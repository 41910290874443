let gainsEnvironment = 'dev';
//let gainsEnvironment = 'prod'

const devConfig = {
	apiGateway: {
		URL: 'https://api-dev.gains.earthi.world',
		REGION: 'eu-west-2'
	},
	cognito: {
		REGION: 'eu-west-2',
		USER_POOL_ID: 'eu-west-2_oKbWrU50D',
		APP_CLIENT_ID: '151dhepbp0uu460t0dbd2bivvu',
		IDENTITY_POOL_ID: 'eu-west-2:471e29e3-6266-450c-aef0-13c08bc87e6a'
	},

	idleTimeout: 1000 * 60 * 30 * 3
	//idleTimeout: 1000 * 10
};

const usersWithElevatedPermissions = ['lucy.colley', 'george.begkas'];

const prodConfig = {
	apiGateway: {
		URL: 'https://api.gains.earthi.world',
		REGION: 'eu-west-2'
	},
	cognito: {
		REGION: 'eu-west-2',
		USER_POOL_ID: 'eu-west-2_plUl26ZwS',
		APP_CLIENT_ID: '5c5i4aom7t7tcrftcr5bt7euvs',
		IDENTITY_POOL_ID: 'eu-west-2:2ec2c7a7-e87e-4e4d-adab-55d302dfa533',
		AuthenticationFlowType: 'USER_PASSWORD_AUTH'
	},
	idleTimeout: 1000 * 60 * 30
	//idleTimeout: 1000 * 10
};

const devBaseUrl = 'https://h0n2gp2t3k.execute-api.eu-west-2.amazonaws.com/dev';
const prodBaseUrl =
	'https://vukufy6563.execute-api.eu-west-2.amazonaws.com/prod';

let config;
let baseUrl;

switch (gainsEnvironment) {
	case 'prod':
		config = prodConfig;
		baseUrl = prodBaseUrl;
		break;
	case 'dev':
	default:
		config = devConfig;
		baseUrl = devBaseUrl;
		break;
}

const tableNames = {
	stockpiles: 'ScrapSteelStockpilesCaptureProcessingData',
	smelters: 'SteelCaptureProcessingData'
};

const endpoints = {
	// general / stats / dashboard

	// general for diff site types
	captures: `captures`,
	sites: 'sites',
	commodities: 'commodities',
	resetCaps: 'reset_captures',
	captureImageData: 'capture_image',
	qcAvailableCaps: 'available-captures',

	// smelters specific
	hotspotsProfile: 'hotspot_profile',
	barplot: 'barplot',

	// stockpiles specific
	pixelCount: 'pixel_count',

	// upload
	postqc: 'post_qc',
	upload: 'upload'
};

const sensors = ['landsat-8-l1tp', 'sentinel-2-l1c'];
const models = ['U-Net'];

const commodities = [
	{
		commodity: 'Copper',
		id: 'copper',
		index: 1,
		class: 'captures-criteria-comms-copper',
		siteTypeId: 1,
		siteTypeStr: 'smelters'
	},
	{
		commodity: 'Nickel',
		id: 'nickel',
		index: 2,
		class: 'captures-criteria-comms-nickel',
		siteTypeId: 1,
		siteTypeStr: 'smelters'
	},
	{
		commodity: 'Steel',
		id: 'steel',
		index: 3,
		class: 'captures-criteria-comms-steel',
		siteTypeId: 1,
		siteTypeStr: 'smelters'
	},
	{
		commodity: 'Iron Ore',
		id: 'iron-ore',
		index: 4,
		class: 'captures-criteria-comms-iron-ore',
		siteTypeId: 3,
		siteTypeStr: 'stockpiles'
	},
	{
		commodity: 'Aircraft',
		id: 'aircraft',
		index: 5,
		class: 'captures-criteria-comms-aircraft',
		siteTypeId: 2,
		siteTypeStr: 'airfields'
	},
	{
		commodity: 'Titanium',
		id: 'titanium',
		index: 6,
		class: 'captures-criteria-comms-titanium',
		siteTypeId: 1,
		siteTypeStr: 'smelters'
	},
	{
		commodity: 'Scrap Steel',
		id: 'scrap-steel',
		index: 7,
		class: 'captures-criteria-comms-scrap-steel',
		siteTypeId: 3,
		siteTypeStr: 'stockpiles'
	}
];

const qcStates = [
	{
		stateString: 'null',
		stateInt: null,
		className: 'text-primary'
	},
	{
		stateString: 'active',
		stateInt: 1,
		className: 'text-primary'
	},
	{
		stateString: 'inactive',
		stateInt: 0,
		className: 'text-secondary'
	},
	{
		stateString: 'invalid',
		stateInt: -1,
		className: 'text-warning'
	}
];

const qcStages = [
	{ id: null, descript: 'Not QCd', qcd: false },
	{ id: 0, descript: 'Not QCd', qcd: false },
	{ id: 3, descript: 'Not QCd - currently in QC', qcd: false },
	{ id: 4, descript: 'QCd - not uploaded', qcd: true },
	{ id: 1, descript: 'QCd - uploaded', qcd: true }
];

const qcUsers = [
	{
		name: 'George',
		value: 'George',
		logoColour: 'rgb(176,218,255)',
		logoTextColour: 'white',
		initials: 'GB',
		qcAlias: 'george.begkas',
		email: 'george.begkas@earthi.co.uk'
	},
	{
		name: 'Gordana',
		value: 'Nikolic',
		logoColour: 'rgb(32, 178, 170)',
		logoTextColour: 'white',
		initials: 'GN',
		qcAlias: 'gordana.nikolic',
		email: 'gordana.nikolic@earthi.co.uk'
	},
	{
		name: 'Qurratul',
		value: 'Khan',
		logoColour: 'rgb(72, 61, 139)',
		logoTextColour: 'white',
		initials: 'QK',
		qcAlias: 'qurratul.khan',
		email: 'qurratul.khan@earthi.co.uk'
	},
	{
		name: 'Guy',
		value: 'Guy',
		logoColour: 'rgb(0,135,90)',
		logoTextColour: 'white',
		initials: 'GC',
		qcAlias: 'guy.cook',
		email: 'guy.cook@earthi.co.uk'
	},
	{
		name: 'Madiha',
		value: 'Madiha',
		logoColour: 'rgb(255,153,31)',
		logoTextColour: 'white',
		initials: 'MA',
		qcAlias: 'madiha.abbas',
		email: 'madiha.abbas@earthi.co.uk'
	},
	{
		name: 'Peter',
		value: 'Peter',
		logoColour: 'rgb(23,43,77)',
		logoTextColour: 'white',
		initials: 'PH',
		qcAlias: 'peter.hausknecht',
		email: 'peter.hausknecht@earthi.co.uk'
	},
	{
		name: 'Varsha',
		value: 'Varsha',
		logoColour: 'rgb(0, 128, 128)',
		logoTextColour: 'white',
		initials: 'VP',
		qcAlias: 'varsha.pradhan',
		email: 'chetan.pradhan@earthi.co.uk'
	},
	{
		name: 'Lucy',
		value: 'Lucy',
		logoColour: 'rgb(70, 130, 180)',
		logoTextColour: 'white',
		initials: 'LC',
		qcAlias: 'lucy.colley',
		email: 'lucy.colley@earthi.co.uk'
	},
	{
		name: 'Josh',
		value: 'Josh',
		logoColour: 'rgb(82,62,170)',
		logoTextColour: 'white',
		initials: 'JL',
		qcAlias: 'joshua.large'
	}
];

const qcAdjust = [
	{
		code: 0,
		from: -1, // inactive
		to: -1 // inactive
	},
	{
		code: 0,
		from: 1, // active
		to: 1 // active
	},
	{
		code: 0,
		from: 0, // invalid
		to: 0 // invalid
	},
	{
		code: 1, // 1 Inactive to Active
		from: -1,
		to: 1
	},
	{
		code: 2, // 2 Active to Inactive
		from: 1,
		to: -1
	},
	{
		code: 3, // 3 Active to Invalid
		from: 1,
		to: 0
	},
	{
		code: 4, // 4 Inactive to Invalid
		from: -1,
		to: 0
	},
	{
		code: 7, // 7 Invalid to Active
		from: 0,
		to: 1
	},
	{
		code: 8, // 7 Invalid to Inactive
		from: 0,
		to: -1
	}
];

const production_states = [
	{
		state: -2,
		descript:
			"Not daily qc'ed. Not in staging or production. Not part of trial smelters and doesn't contribute towards global index",
		formText: 'Titanium site'
	},
	{
		state: -1,
		descript:
			"Not daily qc'ed. Not in staging or production. Not part of trial smelters and doesn't contribute towards global index",
		formText: 'Titanium site'
	},
	{
		state: 0,
		descript:
			"Daily qc'ed. In staging and production. Contributes to global index",
		formText: 'Available in UI'
	},
	{
		state: 1,
		descript:
			"Daily qc'ed. In staging and production. In trial smelters. Does not contribute to global index",
		formText: 'Available in UI but excl from index calcs'
	},
	{
		state: 2,
		descript:
			"Daily qc'ed. In staging, not production. In trial smelters. Does not contribute to global index",
		formText: 'Available in dev UI'
	},
	// {
	// 	state: 3,
	// 	descript: 'Not daily qc\'ed. In staging and prodcuction. Contributes to global index'
	// },
	// {
	// 	state: 4,
	// 	descript: 'Not daily qc\'ed. In staging and production. In trial smelters'
	// },
	{
		state: 5,
		descript:
			"Not daily qc'ed. In staging, not production. In trial smelters. Does not contribute to global index",
		formText: 'Part of daily pre_qc but not in UI'
	}
	// {
	// 	state: 6,
	// 	descript: 'Not daily qc\'ed. In staging, not production. Contributes to global index'
	// }
];

const siteTypesIds = [
	{
		id: 1,
		name: 'smelters',
		commodities: [1, 2, 3, 6],
		icon: 'fa-solid fa-city'
	},
	{
		id: 2,
		name: 'airfields',
		commodities: [5],
		icon: 'fa-solid fa-tower-broadcast'
	},
	{
		id: 3,
		name: 'stockpiles',
		commodities: [4, 7],
		icon: 'fa-solid fa-cubes-stacked'
	}
];

const barplotLabelsConfig = {
    'invalid': { backgroundColor: 'darkgray', borderColor: 'darkgray' },
    'qc-invalid': { backgroundColor: 'gray', borderColor: 'gray' },
    'inactive': { backgroundColor: 'royalblue', borderColor: 'royalblue' },
    'qc-inactive': { backgroundColor: 'blue', borderColor: 'blue' },
    'active': { backgroundColor: 'goldenrod', borderColor: 'goldenrod' },
    'qc-active': { backgroundColor: 'yellow', borderColor: 'yellow' }
};

const errorCodes = {
	400: {
		code: 400,
		name: 'Bad Request',
		description:
			'The server could not understand the request due to invalid syntax.'
	},
	401: {
		code: 401,
		name: 'Unauthorized',
		description:
			'The client must authenticate itself to get the requested response.'
	},
	402: {
		code: 402,
		name: 'Payment Required',
		description: 'This response code is reserved for future use.'
	},
	403: {
		code: 403,
		name: 'Forbidden',
		description: 'The client does not have access rights to the content.'
	},
	404: {
		code: 404,
		name: 'Not Found',
		description: 'The server can not find the requested resource.'
	},
	405: {
		code: 405,
		name: 'Method Not Allowed',
		description:
			'The request method is known by the server but has been disabled and cannot be used.'
	},
	406: {
		code: 406,
		name: 'Not Acceptable',
		description:
			'The server cannot produce a response matching the list of acceptable values defined in the request headers.'
	},
	407: {
		code: 407,
		name: 'Proxy Authentication Required',
		description: 'The client must first authenticate itself with the proxy.'
	},
	408: {
		code: 408,
		name: 'Request Timeout',
		description: 'The server timed out waiting for the request.'
	},
	409: {
		code: 409,
		name: 'Conflict',
		description: 'The request conflicts with the current state of the server.'
	},
	410: {
		code: 410,
		name: 'Gone',
		description:
			'The content requested has been permanently deleted from the server.'
	},
	411: {
		code: 411,
		name: 'Length Required',
		description:
			'The server refuses to accept the request without a defined Content-Length header.'
	},
	412: {
		code: 412,
		name: 'Precondition Failed',
		description:
			'The client has indicated preconditions in its headers which the server does not meet.'
	},
	413: {
		code: 413,
		name: 'Payload Too Large',
		description:
			'The request is larger than the server is willing or able to process.'
	},
	414: {
		code: 414,
		name: 'URI Too Long',
		description:
			'The URI requested by the client is longer than the server is willing to interpret.'
	},
	415: {
		code: 415,
		name: 'Unsupported Media Type',
		description:
			'The media format of the requested data is not supported by the server.'
	},
	416: {
		code: 416,
		name: 'Range Not Satisfiable',
		description:
			"The range specified by the Range header field in the request can't be fulfilled."
	},
	417: {
		code: 417,
		name: 'Expectation Failed',
		description:
			'The server cannot meet the requirements of the Expect request-header field.'
	},
	500: {
		code: 500,
		name: 'Internal Server Error',
		description:
			'The server encountered an unexpected condition that prevented it from fulfilling the request.'
	},
	501: {
		code: 501,
		name: 'Not Implemented',
		description:
			'The server does not support the functionality required to fulfill the request.'
	},
	502: {
		code: 502,
		name: 'Bad Gateway',
		description:
			'The server, while acting as a gateway or proxy, received an invalid response from the upstream server.'
	},
	503: {
		code: 503,
		name: 'Service Unavailable',
		description:
			'The server is not ready to handle the request. Common causes include server maintenance or overload.'
	},
	504: {
		code: 504,
		name: 'Gateway Timeout',
		description:
			'The server is acting as a gateway and cannot get a response in time from the upstream server.'
	},
	505: {
		code: 505,
		name: 'HTTP Version Not Supported',
		description:
			'The HTTP version used in the request is not supported by the server.'
	}
};

// 3	1	steel
// 4	3	iron ore
// 2	1	nickel
// 6	1	titanium
// 1	1	copper
// 5	2	aircraft
// 7	3	scrap steel

// 2	airfield
// 3	stockpiles
// -100	invisible
// 9	rolling_lines
// 10	pellet_plant
// 1	smelter
// 12	coke_ovens
// 11	bosbof
// 4	blast_furnaces
// 13	sinter
// 14	eaf
// 0	unknown
// 5	blast_furnace
// 6	slag
// 7	steel_inventories
// 8	ferrous_scrap_flux_beds

const sentinelEval =
	'Ly9WRVJTSU9OPTMKZnVuY3Rpb24gc2V0dXAoKSB7CiAgcmV0dXJuIHsKICAgIGlucHV0OiBbIkIxMiIsIkIxMSIsIkI4QSIsICJkYXRhTWFzayJdLAogICAgb3V0cHV0OiB7IGJhbmRzOiA0IH0KICB9Owp9CgpmdW5jdGlvbiBldmFsdWF0ZVBpeGVsKHNhbXBsZSkgewogIAogIHJldHVybiBbMi41ICogc2FtcGxlLkIxMiwgMi41ICogc2FtcGxlLkIxMSwgMi41ICogc2FtcGxlLkI4QSwgc2FtcGxlLmRhdGFNYXNrXTsKfQ%3D%3D';
const landsatEval =
	'Ly9WRVJTSU9OPTMKZnVuY3Rpb24gc2V0dXAoKSB7CiAgcmV0dXJuIHsKICAgIGlucHV0OiBbIkIwNyIsIkIwNiIsIkIwNSIsICJkYXRhTWFzayJdLAogICAgb3V0cHV0OiB7IGJhbmRzOiA0IH0KICB9Owp9CgpmdW5jdGlvbiBldmFsdWF0ZVBpeGVsKHNhbXBsZSkgewogIAogIHJldHVybiBbMi41ICogc2FtcGxlLkIwNywgMi41ICogc2FtcGxlLkIwNiwgMi41ICogc2FtcGxlLkIwNSwgc2FtcGxlLmRhdGFNYXNrXTsKfQ%3D%3D';

const exportConfig = {
	...config,
	baseUrl,
	commodities,
	production_states,
	qcStates,
	qcUsers,
	qcAdjust,
	sentinelEval,
	landsatEval,
	endpoints,
	tableNames,
	gainsEnvironment,
	siteTypesIds,
	qcStages,
	sensors,
	models,
	siteTypesIds,
	errorCodes,
	usersWithElevatedPermissions,
	barplotLabelsConfig
};

export default exportConfig;
