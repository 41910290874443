import React, { useContext } from 'react';
import { Row, div } from 'reactstrap';
import { Tooltip } from 'react-tooltip';
import { pipelineContext } from '../../../contexts/pipelineContext';
import { useNavigate } from 'react-router-dom';

const QCCategory = () => {
	const navigate = useNavigate();

	const pipelineContextQCCat = useContext(pipelineContext);

	const handleSetQCCategory = (qc_cat) => {
		pipelineContextQCCat.setComm(qc_cat.toLowerCase());
		pipelineContextQCCat.setProgressBar({
			width: 2 * (100 / 7),
			title: 'QC Type'
		});
	};

	return (
		<>
			<hr></hr>
			<div className="px-3 mx-3">
				<div className="row">
					<div className="col-6 my-2 my-md-0 col-md-3 order-1 order-md-6 d-flex justify-content-start">
						<div className="d-flex flex-row align-items-center">
							<button
								className="btn interactButtonSecondary"
								style={{ maxHeight: '40px', width: '200px' }}
								onClick={() => navigate('/')}
							><i className="fa-solid fa-left-long pe-3"></i>
								Exit QC
							</button>
							<h1 className="ms-5 mt-2">QC</h1>
						</div>
					</div>
				</div>

				{/* <div className="row mt-2">
					<h1>QC</h1>
				</div> */}

				<Row>
					<div className="d-flex justify-content-start">
						{/* <button
							className="btn interactButtonSecondary backButton"
							style={{ maxHeight: '40px', width: '200px' }}
							disabled
						>
							<i class="fa-solid fa-left-long pe-3"></i>
							Back
						</button> */}
					</div>
					<div></div>
					<div className="d-flex justify-content-end"></div>
				</Row>
				<h4 className="py-1 py-md-3">Select the site type of QC to perform</h4>

				<Row className="d-flex justify-content-center justify-content-md-start mt-5">
					<>
						<div className="col-10 col-sm-6 col-md-6 col-lg-4 col-xl-3 my-2 my-lg-0">
							<button
								onClick={(e) => handleSetQCCategory('Smelters')}
								className="QCTypeButtons"
							>
								<div
									className="card QCTypeButtonsBtn"
									style={{ width: '100%', height: '130px' }}
								>
									<div className="card-body  d-flex flex-column justify-content-center">
										<h5 className="card-title ">
											Smelters <i className="fa-solid fa-city ps-3"></i>
										</h5>
										<p className="card-text">
											steel, copper, titanium and nickel
										</p>
									</div>
								</div>
							</button>
						</div>
						<div className="col-10 col-sm-6 col-md-6 col-lg-4 col-xl-3 my-2 my-lg-0">
							<button
								onClick={(e) => handleSetQCCategory('Stockpiles')}
								className="QCTypeButtons"
							>
								<div
									className="card QCTypeButtonsBtn"
									style={{ width: '100%', height: '130px' }}
								>
									<div className="card-body d-flex flex-column justify-content-center">
										<h5 className="card-title ">
											Stockpiles{' '}
											<i className="fa-solid fa-cubes-stacked ps-3"></i>
										</h5>
										<p className="card-text">iron ore and scrap steel</p>
									</div>
								</div>
							</button>
						</div>
						<div className="col-10 col-sm-6 col-md-6 col-lg-4 col-xl-3 my-2 my-lg-0">
							<button
								//onClick={(e) => handleSetQCCategory('Re QC Form')}
								data-tooltip-id="reQCType"
								className="QCTypeButtons"
							>
								<div
									className="card QCTypeButtonsBtn"
									style={{ width: '100%', height: '130px', backgroundColor: '#f7f7f7' }}
								>
									<div className="card-body d-flex flex-column align-items-center justify-content-center">
										<h5 className="card-title">
											Airfields{' '}
											<i className="fa-solid fa-tower-broadcast ps-3"></i>
										</h5>
										<p className="card-text">aircraft</p>
									</div>
								</div>
							</button>

							<Tooltip
								id="reQCType"
								place="bottom"
								effect="solid"
								style={{ backgroundColor: 'grey' }}
							>
								The option to QC airfields is not currently available
							</Tooltip>
						</div>
					</>

					{/* {loading ? (
					<div className="d-flex justify-content-evenly col-5">
						<button
							className="btn interactButton mx-2"
							style={{ width: '150px' }}
							disabled
						>
							Daily QC <i className="fa fa-solid fa-calendar-day ps-3"></i>
						</button>

						<button
							className="btn interactButton mx-2"
							style={{ width: '150px' }}
							disabled
						>
							Historic QC <i className="fa fa-solid fa-calendar-days ps-3"></i>
						</button>

						<button
							className="btn interactButton mx-2"
							style={{ width: '150px' }}
							disabled
						>
							Re QC <i className="fa fa-solid fa-calendar-check ps-3"></i>
						</button>
					</div>
				) : (
					<div className="d-flex justify-content-evenly col-5">
						<button
							className="btn interactButton mx-2"
							style={{ width: '150px' }}
							onClick={(e) => handleSetQCCategory('Daily QC Form')}
							data-tooltip-id="dailyQC"
						>
							Daily QC <i className="fa fa-solid fa-calendar-day ps-3"></i>
						</button>
						<Tooltip id="dailyQC" place="bottom" effect="solid">
							Perform a routine QC on images captured in the last day
						</Tooltip>

						<button
							className="btn interactButton mx-2"
							style={{ width: '150px' }}
							onClick={(e) => handleSetQCCategory('Historic QC Form')}
							data-tooltip-id="historicQC"
						>
							Historic QC <i className="fa fa-solid fa-calendar-days ps-3"></i>
						</button>
						<Tooltip id="historicQC" place="bottom" effect="solid">
							Perform a historic QC only on captures from a particular site
						</Tooltip>

						<button
							className="btn interactButton mx-2"
							style={{ width: '150px' }}
							onClick={(e) => handleSetQCCategory('Re-QC Form')}
							data-tooltip-id="reQC"
						>
							Re QC <i className="fa fa-solid fa-calendar-check ps-3"></i>
						</button>
						<Tooltip id="reQC" place="bottom" effect="solid">
							Re-QC previously QC'd captures
						</Tooltip>
					</div>
				)} */}
				</Row>
			</div>
		</>
	);
};

export default QCCategory;
