import React, { useContext, useEffect, useState } from 'react';
import config from '../config';
import { Tooltip } from 'react-tooltip';
import { pipelineContext } from '../contexts/pipelineContext';
import { SkeletonTable } from './SkeletonTable';

const SmeltersTableBasic = ({ props, captures, height }) => {
	const [caps, setCaps] = useState();
	const { capsInPipeline, currentCaptureIndex, straightToUpload } =
		useContext(pipelineContext);

	useEffect(() => {
		// if a specific set of captures is not used, use context caps
		if (captures) {
			setCaps(captures);
		} else {
			setCaps(capsInPipeline);
		}
	}, [caps]);

	
	if (caps) {
		return (
			<table className="captureTable" style={{ maxHeight: height ? `${height}px` : '', overflowY: height ? 'scroll': '' }}>
				<thead>
					<tr>
						<th scope="col">#</th>
						<th scope="col">Capture ID</th>
						<th scope="col">Site</th>
						<th scope="col">Captured</th>
						<th scope="col">Pre QC State</th>
						<th scope="col">Set State</th>
						<th scope="col">QC'd</th>
						<th scope="col">QC User</th>
					</tr>
				</thead>
	
				<tbody>
					{caps.map((capture, idx) => (
						<tr key={idx}>
							{currentCaptureIndex === idx ? (
								<>
									<td scope="row" className="table-secondary">
										{idx + 1}
									</td>
									<td
										className="table-secondary"
										data-bs-dismiss="modal"
										//onClick={(e) => openThisCapture(e, idx)}
										//onClick={(e) => sentDataToParent(e, idx)}
									>
										{capture.id}
									</td>
									<td className="table-secondary">{capture.site_name.length > 20 ? capture.site_name.slice(0,20)+'...' : capture.site_name}</td>
									<td className="table-secondary">{capture.captured ? capture.captured.slice(0,10) : ''}</td>
									<td className="table-secondary">
										{
											config.qcStates.filter(
												(qstate) => qstate.stateInt === capture.initial_state
											)[0].stateString
										}
									</td>
	
									{straightToUpload ? (
										<>
											<td className="table-secondary">
												{
													config.qcStates.filter(
														(qstate) => qstate.stateInt === capture.state
													)[0].stateString
												}
											</td>
										</>
									) : (
										<>
											{config.qcStates
												.map((obj) => obj.stateInt)
												.includes(capture.newQC) ? (
												<td className="table-secondary">
													{
														config.qcStates.filter(
															(qstate) => qstate.stateInt === capture.newQC
														)[0].stateString
													}
												</td>
											) : (
												<td className="table-secondary"></td>
											)}
										</>
									)}
	
									{straightToUpload ? (
										<>
											{capture.state != null ? (
												<td className="table-secondary">
													<i class="fa-solid fa-circle-check"></i>
												</td>
											) : (
												<td className="table-secondary">
													<i class="fa-solid fa-circle-xmark"></i>
												</td>
											)}
										</>
									) : (
										<>
											{config.qcStates
												.map((obj) => obj.stateInt)
												.includes(capture.newQC) ? (
												<td className="table-secondary">
													<i class="fa-solid fa-circle-check"></i>
												</td>
											) : (
												<td className="table-secondary">
													{/* <i class="fa-solid fa-circle-xmark"></i> */}
												</td>
											)}
										</>
									)}
	
									{/* {config.qcStates
										.map((obj) => obj.stateInt)
										.includes(capture.newQC) ? (
										<td className="table-secondary">
											<i class="fa-solid fa-circle-check"></i> 
	
											{capture.newQC}
										</td>
									) : (
										<td className="table-secondary">
											<i class="fa-solid fa-circle-xmark"></i>
										</td>
									)} */}
									<td className="table-secondary">{`${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`}</td>
								</>
							) : (
								<>
									<td scope="row">{idx + 1}</td>
									<td
										data-bs-dismiss="modal"
										// onClick={(e) => openThisCapture(e, idx)}
										//onClick={(e) => sentDataToParent(e, idx)}
									>
										{capture.id}
									</td>
									<td>{capture.site_name.length > 20 ? capture.site_name.slice(0,20)+'...' : capture.site_name}</td>
									<td>{capture.captured ? capture.captured.slice(0,10) : ''}</td>
									<td>
										{
											config.qcStates.filter(
												(qstate) => qstate.stateInt === capture.initial_state
											)[0].stateString
										}
									</td>
									{straightToUpload ? (
										<>
											<td>
												{
													config.qcStates.filter(
														(qstate) => qstate.stateInt === capture.state
													)[0].stateString
												}
											</td>
										</>
									) : (
										<>
											{config.qcStates
												.map((obj) => obj.stateInt)
												.includes(capture.newQC) ? (
												<td>
													{
														config.qcStates.filter(
															(qstate) => qstate.stateInt === capture.newQC
														)[0].stateString
													}
												</td>
											) : (
												<td></td>
											)}
										</>
									)}
	
									{straightToUpload ? (
										<>
											{capture.state != null ? (
												<td>
													<i class="fa-solid fa-circle-check"></i>
												</td>
											) : (
												<td>
													<i class="fa-solid fa-circle-xmark"></i>
												</td>
											)}
										</>
									) : (
										<>
											{config.qcStates
												.map((obj) => obj.stateInt)
												.includes(capture.newQC) ? (
												<td>
													<i class="fa-solid fa-circle-check"></i>
												</td>
											) : (
												<td>{/* <i class="fa-solid fa-circle-xmark"></i> */}</td>
											)}
										</>
									)}
									<td>{`${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`}</td>
								</>
							)}
						</tr>
					))}
				</tbody>
			</table>
		);
	} else {
		return (
			<SkeletonTable numRows={10} cols={['#', 'Capture ID', 'Site', 'Captured', 'Pre QC State', 'Set State', 'QCd', 'QC User' ]} height={300}/>
		)
	}

};

export default SmeltersTableBasic;
