// external exports
import React, { useContext, useEffect, useState } from 'react';
import { Col } from 'reactstrap';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// local imports
import config from '../../config';
import {
	QCReport,
	QCProgressBar,
	QCCaptures,
	QCProgressModal,
	QCing,
	ExitQCModal,
	QCUpload,
	QCType,
	QCFormHistoric,
	QCFormDaily,
	QCFinishedModal,
	QCCategory
} from '../QC';

// custom hooks & context
import useGainsQCApi from '../../services/useGainsQCApi';
import { pipelineContext } from '../../contexts/pipelineContext';

// hardcoded data
import stockpiles from '../../helpers/stockpiles';
import exportConfig from '../../config';

export default function QCPage(props) {
	const pipelineContextQCPage = useContext(pipelineContext);

	const fetchCapturesObj = useGainsQCApi();
	const postCaptureStateSmelters = useGainsQCApi();
	const postCaptureStateStockpiles = useGainsQCApi();
	const sendCapsBack = useGainsQCApi();
	const [sendBackCapsList, setSendBackCapsList] = useState([]);

	let [currentCapture, setCurrentCapture] = useState();

	const [loading, setLoading] = useState(true);
	const [qcState, setQCState] = useState(1);
	const [disagreeReason, setDisagreeReason] = useState(0);
	const [formValue, setFormValue] = useState({
		formSiteId: '',
		formStartDate: '',
		formEndDate: new Date().toISOString().substring(0, 10),
		formAutoQC: true,
		formEndDateNow: '',
		fullHistory: true,
		formSensors: ['sentinel-2-l1c', 'landsat-8-l1tp'],
		formCommodities: [3],
		formProdStates: [0, 1],
		inclQCdCaps: false,
		capsCountLimit: null
	});

	const [formValid, setFormValid] = useState(false);
	const [filters, setFilters] = useState({
		// AUTO QC disabled for now
		autoQCFilterStatusInvalid: false,
		autoQCFilterStatusInactive: false,
		autoQCFilterCR: '',
		autoQCFilterMinSwath: '',
		autoQCFilter1211Invalid: '',
		autoQCFilter1211Active: ''
	});

	const [capturesUrl, setCapturesUrl] = useState('');
	const [editedGeojson, setEditedGeojson] = useState('');

	const location = useLocation(); // Get current page
	console.log(location.pathname);

	// 1 = accept, 0 = reject
	const [stockpilesFormValue, setStockpilesFormValue] = useState(1);

	let [featureCollection, setFeatureCollection] = useState();
	let [featureLayers, setFeatureLayers] = useState({ layers: [] });

	const fetchSites = useGainsQCApi();
	console.log(formValue);
	console.log(pipelineContextQCPage);

	useEffect(() => {
		/**
		 * if user goes straight to QC from Dashboard
		 */

		document.title = 'GAINS | QC';
		if (pipelineContextQCPage.straightToQC) {
			pipelineContextQCPage.setProgressBar({
				width: 4 * (100 / 7),
				title: 'View Captures'
			});
		} else if (pipelineContextQCPage.straightToUpload) {
			pipelineContextQCPage.setQCPerc(100);
			pipelineContextQCPage.setProgressBar({
				width: 6 * (100 / 7),
				title: 'Upload Captures'
			});

			// !!
			if (
				pipelineContextQCPage.capsInPipeline[0].hasOwnProperty('model_version')
			) {
				pipelineContextQCPage.setComm('stockpiles');
			} else {
				pipelineContextQCPage.setComm('smelters');
			}
		} else {
			setTimeout(() => {
				// pipelineContextQCPage.setProgressBar({
				// 	width: 7 * (100 / 7),
				// 	title: 'QC Report'
				// });
				pipelineContextQCPage.setProgressBar({
					width: 1 * (100 / 7),
					title: ''
				});
				// pipelineContextQCPage.setProgressBar({
				// 	width: 6 * (100 / 7),
				// 	title: 'Upload Captures'
				// });
			}, 500);

			let today = new Date();

			// set default form values
			setFormValue({
				...formValue,
				formEndDateNow: today.toLocaleTimeString(),
				formAutoQC: false
			});

			setLoading(false);
		}

		/**
		 * adjust stockpiles object to add additional props
		 * for leaflet feature
		 */

		if (pipelineContextQCPage.comm === 'stockpiles') {
			let idCounter = 1;
			stockpiles.features.forEach((feature) => {
				feature.properties.id = idCounter;
				feature.status = '';
				feature.newGeom = '';
				idCounter++;
			});

			// change to API call instead of hardcoded data
			setFeatureCollection(stockpiles);
			setFeatureLayers({ layers: [] });
		}

		pipelineContextQCPage.setOnQCPage(true);
	}, []);

	useEffect(() => {
		/**
		 * check if this actually does anything !!
		 */

		const handleBeforeUnload = (event) => {
			event.preventDefault();
			event.returnValue = '';
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	useEffect(() => {
		/**
		 * once a category has been chosen (smelter/stockpiles)
		 * fetch sites for that comm to use in QCFormHistoric
		 */

		if (pipelineContextQCPage.comm) {
			fetchSites.makeRequest(
				`${exportConfig.baseUrl}/${pipelineContextQCPage.comm}/sites`,
				'GET',
				null,
				'',
				1000
			);

			let today = new Date();
			setFormValue({
				...formValue,
				formEndDateNow: today.toLocaleTimeString(),
				formAutoQC: false
			});
		}
	}, [pipelineContextQCPage.comm]);

	const handleSubmit = async (e) => {
		/**
		 * fetch captures based on user's form
		 * move to View Captures page
		 */

		e.preventDefault();
		setLoading(true);

		pipelineContextQCPage.setProgressBar({
			width: 4 * (100 / 7),
			title: 'View Captures'
		});

		await fetchCapturesObj.makeRequest(
			`${config.baseUrl}/${pipelineContextQCPage.comm}/${capturesUrl}`,
			'GET',
			null,
			'captures'
		);
	};

	useEffect(() => {
		/**
		 * when captures data arrives, sort data
		 * set QC In Progress to true
		 */

		if (fetchCapturesObj?.data?.data) {
			let sortedList = fetchCapturesObj.data.data.sort((a, b) => {
				// order by site_id
				if (a.site_id < b.site_id) return -1;
				if (a.site_id > b.site_id) return 1;

				// then by capture date
				return new Date(a.captured) - new Date(b.captured);
			});

			const capsCountLimit =
				formValue.capsCountLimit !== null &&
				Number.isInteger(formValue.capsCountLimit)
					? formValue.capsCountLimit
					: sortedList.length;

			let keepCaps = sortedList
				.filter((cap) => cap['stac_item_id'] !== null)
				.filter((cap) => cap.state === null)
				.slice(0, capsCountLimit);

			pipelineContextQCPage.setCapsInPipeline(keepCaps);

			let sendCaps = sortedList.filter((cap) => !keepCaps.includes(cap));

			console.log(fetchCapturesObj.data.data)
			console.log(sendCaps)
			console.log(keepCaps)

			setSendBackCapsList(sendCaps.map((cap) => cap.id));

			

			if (fetchCapturesObj.data.data.length > 0) {
				pipelineContextQCPage.setQCInProgress(true);
			}
		}
	}, [fetchCapturesObj.data]);

	const moveToQC = async (caps, auto_caps) => {
		/**
		 * move to QC page
		 * set first capture
		 */

		console.log(sendBackCapsList);

		//if limit was set, send back rest of caps here
		if (sendBackCapsList.length > 0) {
			await sendCapsBack.makeRequest(
				`${exportConfig.baseUrl}/${pipelineContextQCPage.comm}/remove_from_queue`,
				'POST',
				{
					commodityIds: `${formValue.formCommodities.join(',')}`,
					qcUser: `${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`,
					captureIds: sendBackCapsList.join(',')
				},
				'sending caps back'
			);
		}

		setCurrentCapture(
			pipelineContextQCPage.capsInPipeline[
				pipelineContextQCPage.currentCaptureIndex
			]
		);

		pipelineContextQCPage.setProgressBar({
			width: 5 * (100 / 7),
			title: 'Perform QC'
		});
	};

	const onQCSubmit = async (e) => {
		/**
		 * submit user's QC response
		 * POST capture
		 */

		setLoading(true);

		e.preventDefault();

		let caps = pipelineContextQCPage.capsInPipeline;
		let cci = pipelineContextQCPage.currentCaptureIndex;
		let cc = caps[cci];

		// if the capture has NOT been QCd already OR user is changing the state
		// change use of newQC to currentCapture.state

		// if capture hasn't been QCd OR
		// if changing QC value OR
		// if changing disagree reason
		if (
			!cc.hasOwnProperty('beenQCd') ||
			cc.newQC !== qcState ||
			(cc.hasOwnProperty('disagreeReason') &&
				cc.disagreeReason !== disagreeReason)
		) {
			let apiString;

			if (disagreeReason) {
				apiString = `captures?table=SteelCaptureProcessingData&captureId=${
					currentCapture.id
				}&state=${qcState}&qcUser=${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}&reason=${disagreeReason}&useSmoke=0`;
			} else {
				apiString = `captures?table=SteelCaptureProcessingData&captureId=${
					currentCapture.id
				}&state=${qcState}&qcUser=${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}&useSmoke=0`;
			}

			await postCaptureStateSmelters.makeRequest(
				`${config.baseUrl}/${pipelineContextQCPage.comm}/${apiString}`,
				'POST',
				null,
				//`posting capture ${currentCapture.id}`,
				''
			);
		} else {
			// do nothing
		}
	};

	useEffect(() => {
		/**
		 * upon posting capture data
		 * update captures object to change params locally
		 *
		 * determine first capture in pipeline which has not yet been QC'd
		 * determine how many caps are QC'd
		 */

		// if capture data posted successfully
		if (!postCaptureStateSmelters.error && postCaptureStateSmelters.data) {
			let params = JSON.parse(postCaptureStateSmelters.data.body.params);
			currentCapture.newQC = qcState;
			currentCapture.beenQCd = true;
			currentCapture.state = qcState;
			currentCapture.qc_state = 4;
			currentCapture.disagreeReason = disagreeReason;
			currentCapture.qc_date = params['qc_date'];

			const firstCapNotQCd = pipelineContextQCPage.capsInPipeline.filter(
				(obj) => !obj.hasOwnProperty('newQC')
			)[0];
			const firstCapIndex =
				pipelineContextQCPage.capsInPipeline.indexOf(firstCapNotQCd);
			let numCapsQCd = pipelineContextQCPage.capsInPipeline.filter((cap) =>
				cap.hasOwnProperty('beenQCd')
			).length;
			let cci = pipelineContextQCPage.currentCaptureIndex;

			console.log(firstCapNotQCd);
			console.log(pipelineContextQCPage.capsInPipeline.length);
			console.log(firstCapIndex);
			console.log(numCapsQCd);
			console.log(cci);

			// Ensure cci is within bounds
			if (cci >= 0 && cci < pipelineContextQCPage.capsInPipeline.length) {
				// Check if cci is not the final capture
				if (cci < pipelineContextQCPage.capsInPipeline.length - 1) {
					console.log('Processing current capture...');

					// If not all captures have been QC'd
					if (numCapsQCd < pipelineContextQCPage.capsInPipeline.length) {
						console.log('Moving to the next capture...');
						// Move to next capture
						pipelineContextQCPage.setCurrentCaptureIndex(cci + 1);
					} else {
						console.log('All captures have been QC’d. Stopping QC process.');
						setLoading(false);
					}
				} else {
					console.log(
						'Reached the last capture. Resetting to the first non-QC’d capture...'
					);
					// Set to the first non-QC'd capture in pipeline
					pipelineContextQCPage.setCurrentCaptureIndex(firstCapIndex);
				}
			} else {
				console.error('Error: Current capture index (cci) is out of bounds.');
			}

			// // if currentCaptureIndex is not the final capture
			// if (cci <= pipelineContextQCPage.capsInPipeline.length - 1) {
			// 	console.log('here 1');

			// 	// if not all captures have been QC'd
			// 	if (numCapsQCd !== pipelineContextQCPage.capsInPipeline.length) {
			// 		// move to next capture
			// 		console.log('here 2');
			// 		pipelineContextQCPage.setCurrentCaptureIndex(cci + 1);
			// 	} else {
			// 		console.log('stop QC here');
			// 		setLoading(false);
			// 	}

			// 	// set to first non-QC'd capture in pipeline
			// } else {
			// 	console.log('here 3');
			// 	pipelineContextQCPage.setCurrentCaptureIndex(firstCapIndex);
			// }

			// set QC % in progress bar
			let capsQCd = pipelineContextQCPage.capsInPipeline.filter((cap) =>
				cap.hasOwnProperty('newQC')
			).length;
			pipelineContextQCPage.setQCPerc(
				((capsQCd / pipelineContextQCPage.capsInPipeline.length) * 100).toFixed(
					0
				)
			);

			// if not posted succesfully
		} else if (postCaptureStateSmelters.error) {
			console.log(postCaptureStateSmelters.error);
			currentCapture.error = postCaptureStateSmelters.error;
			setLoading(false);

			//currentCapture.newQC = 'failed';
		}
	}, [postCaptureStateSmelters.data, postCaptureStateSmelters.error]);

	const onStockpilesQCSubmit = async (e) => {
		e.preventDefault();

		let apiString = '';
		apiString = `captures?table=ScrapSteelStockpilesCaptureProcessingData&captureId=${
			currentCapture.id
		}&state=${stockpilesFormValue}&qcUser=${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`;

		setLoading(true);

		await postCaptureStateStockpiles.makeRequest(
			`${config.baseUrl}/${pipelineContextQCPage.comm}/${apiString}`,
			'PUT',
			null,
			`posting capture ${currentCapture.id}`
		);
	};

	useEffect(() => {
		if (!postCaptureStateStockpiles.error && postCaptureStateStockpiles.data) {
			let data = postCaptureStateStockpiles.data.body;
			currentCapture.newQC = data.state;
			currentCapture.beenQCd = true;
			currentCapture.state = data.state;
			currentCapture.qc_state = data.qc_state;
			currentCapture.qc_date = data.qc_date;

			setLoading(false);

			const firstCapNotQCd = pipelineContextQCPage.capsInPipeline.filter(
				(obj) => !obj.hasOwnProperty('newQC')
			)[0];
			const firstCapIndex =
				pipelineContextQCPage.capsInPipeline.indexOf(firstCapNotQCd);
			let numCapsQCd = pipelineContextQCPage.capsInPipeline.filter((cap) =>
				cap.hasOwnProperty('beenQCd')
			).length;
			let cci = pipelineContextQCPage.currentCaptureIndex;

			if (cci <= pipelineContextQCPage.capsInPipeline.length - 1) {
				if (numCapsQCd !== pipelineContextQCPage.capsInPipeline.length) {
					pipelineContextQCPage.setCurrentCaptureIndex(cci + 1);
				} else {
					console.log('stop QC here');
				}
			} else {
				pipelineContextQCPage.setCurrentCaptureIndex(firstCapIndex);
			}

			let capsQCd = pipelineContextQCPage.capsInPipeline.filter((cap) =>
				cap.hasOwnProperty('newQC')
			).length;
			pipelineContextQCPage.setQCPerc(
				((capsQCd / pipelineContextQCPage.capsInPipeline.length) * 100).toFixed(
					0
				)
			);
		} else if (postCaptureStateStockpiles.error) {
			currentCapture.newQC = 'failed';
		}
	}, [postCaptureStateStockpiles.data, postCaptureStateStockpiles.error]);

	useEffect(() => {
		// update current capture locally on this page
		setCurrentCapture(
			pipelineContextQCPage.capsInPipeline[
				pipelineContextQCPage.currentCaptureIndex
			]
		);
	}, [pipelineContextQCPage.currentCaptureIndex]);

	return (
		<>
			{props.authProps.isAuthenticated ? (
				<Col className="px-0">
					<div className="contentContainer" style={{ overflow: 'auto' }}>
						<QCProgressBar />

						<ExitQCModal
							props={props}
							commodity={formValue.formCommodities}
							user={props.authProps.userAttributes}
						/>

						<QCProgressModal props={props} loading={loading} />

						<QCFinishedModal
							props={props}
							commodity={formValue.formCommodities}
						/>

						{!pipelineContextQCPage.straightToQC && (
							<>
								{/* {progressBar.title === 'QC Type' && (
									<QCSummaryInfo
										loading={loading}
										capturesQCStatus={capturesQCStatus}
									/>
								)} */}

								{pipelineContextQCPage.progressBar.title === '' && (
									<QCCategory />
								)}

								{pipelineContextQCPage.progressBar.title === 'QC Type' && (
									<QCType loading={loading} />
								)}

								{pipelineContextQCPage.progressBar.title === 'Daily' && (
									<QCFormDaily
										handleSubmit={handleSubmit}
										formValue={formValue}
										setFormValue={setFormValue}
										formValid={formValid}
										setFormValid={setFormValid}
										loading={fetchCapturesObj.loading}
										error={fetchCapturesObj.error}
										setCapturesUrl={setCapturesUrl}
										user={props.authProps.userAttributes}
									/>
								)}

								{pipelineContextQCPage.progressBar.title === 'Historic' && (
									<QCFormHistoric
										fetchCapturesObj={fetchCapturesObj}
										handleSubmit={handleSubmit}
										formValue={formValue}
										setFormValue={setFormValue}
										formValid={formValid}
										setFormValid={setFormValid}
										loading={fetchCapturesObj.loading}
										error={fetchCapturesObj.error}
										setCapturesUrl={setCapturesUrl}
										fetchSites={fetchSites}
										props={props}
									/>
								)}
							</>
						)}

						{pipelineContextQCPage.progressBar.title === 'View Captures' && (
							<QCCaptures
								filters={filters}
								formValue={formValue}
								fetchCapturesLoading={fetchCapturesObj.loading}
								moveToQC={moveToQC}
								setFilters={setFilters}
								fetchCapturesError={fetchCapturesObj.error}
								user={`${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`}
							/>
						)}

						{pipelineContextQCPage.progressBar.title === 'Perform QC' && (
							<QCing
								loading={loading}
								setLoading={setLoading}
								currentCapture={currentCapture}
								manualQCCaptures={pipelineContextQCPage.capsInPipeline}
								currentCaptureIndex={pipelineContextQCPage.currentCaptureIndex}
								qcState={qcState}
								setQCState={setQCState}
								onQCSubmit={onQCSubmit}
								onStockpilesQCSubmit={onStockpilesQCSubmit}
								setDisagreeReason={setDisagreeReason}
								editedGeojson={editedGeojson}
								setEditedGeojson={setEditedGeojson}
								featureCollection={featureCollection}
								setFeatureCollection={setFeatureCollection}
								featureLayers={featureLayers}
								setFeatureLayers={setFeatureLayers}
								stockpilesFormValue={stockpilesFormValue}
								setStockpilesFormValue={setStockpilesFormValue}
								postCaptureStateSmelters={postCaptureStateSmelters}
							/>
						)}

						{pipelineContextQCPage.progressBar.title === 'Upload Captures' && (
							<QCUpload props={props} formValue={formValue} />
						)}

						{pipelineContextQCPage.progressBar.title === 'QC Report' && (
							<QCReport
								props={props}
								manualQCCaptures={pipelineContextQCPage.capsInPipeline}
								qcInProgress={pipelineContextQCPage.qcInProgress}
							/>
						)}
					</div>
				</Col>
			) : (
				<Navigate to="/login" />
			)}
		</>
	);
}
