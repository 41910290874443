import React, { useEffect } from 'react';
import StockpilesTableBasic from '../../StockpilesTableBasic';
import { useContext } from 'react';
import { pipelineContext } from '../../../contexts/pipelineContext';
import SmeltersTableBasic from '../../SmeltersTableBasic';
import { SkeletonTable } from '../../SkeletonTable';
import CapturesTable from '../../CapturesTable';
import { smelterFields, stockpileFields } from '../tableFields';

const QCReport = ({ props, manualQCCaptures, qcInProgess, qcCat }) => {
	const pipelineContextQCReport = useContext(pipelineContext);

	console.log(pipelineContextQCReport);

	return (
		<>
			<hr></hr>
			<div className=" ms-4">
				<h1>QC Finished</h1>
				<h5>See below information about the performed QC</h5>

				<div className="row d-flex justify-content-center mt-4">
					<div className="col-3 d-flex justify-content-center">
						<table className="table qc-report-table ">
							<tbody>
								<tr>
									<td>Type</td>
									<td className="fw-bold">{pipelineContextQCReport.comm}</td>
								</tr>

								<tr>
									<td>No. captures</td>
									<td>{pipelineContextQCReport.capsInPipeline.length}</td>
								</tr>

								<tr>
									<td>Captures QC'd</td>
									<td>{pipelineContextQCReport.qcPerc}%</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className="col-3 d-flex justify-content-center">
						<table className="table qc-report-table">
							<tbody>
								<tr>
									<td>Date</td>
									<td>{new Date().toLocaleString().slice(0, 10)}</td>
								</tr>

								<tr>
									<td>Time</td>
									<td>{new Date().toLocaleString().slice(12, 17)}</td>
								</tr>

								<tr>
									<td>User</td>
									<td>
										{props.authProps.userAttributes.givenName.toLowerCase()}.
										{props.authProps.userAttributes.familyName.toLowerCase()}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div className="row my-3">
					<SmeltersTableBasic
						props={props}
						// capturesToReset={pipelineContextQCReport.capsInPipeline.filter(
						// 	(cap) => cap.beenQCd === false
						// )}
						postQCPerformed={true}
						uploaded={true}
						uploadedToDev={true}
						uploadedToProd={true}
						allowJumpCapture={false}
						captures={pipelineContextQCReport.capsInPipeline}
						height={600}
					/>
				</div>
			</div>
		</>
	);
};

export default QCReport;
