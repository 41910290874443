const formatDate = (date) => {
	if (date !== null && date !== undefined && date.length > 0) {
		var day;
		var month;
		var year;
		if (date.length > 9) {
			day = date.slice(8, 10);
			month = date.slice(5, 7);
			year = date.slice(0, 4);
		} else {
			day = date.slice(8);
			month = date.slice(5, 7);
			year = date.slice(0, 4);
		}

		let date2 = `${day}-${month}-${year}`;
		return date2;
	} else {
		return '-';
	}
};

export default formatDate;
