import React, { useContext, useState, useEffect } from 'react';
import { pipelineContext } from '../contexts/pipelineContext';
import { Tooltip } from 'react-tooltip';

const StockpilesTableBasic = ({
	props,
	capturesToReset,
	postQCPerformed,
	uploaded,
	uploadedToDev,
	uploadedToProd,
	allowJumpCapture,
	captures
}) => {
	const { capsInPipeline, currentCaptureIndex, setCurrentCaptureIndex } =
		useContext(pipelineContext);

	const [caps, setCaps] = useState();

	useEffect(() => {
		// if a specific set of captures is not used, use context caps
		if (captures) {
			setCaps(captures);
		} else {
			setCaps(capsInPipeline);
		}
	}, []);

	console.log(caps)
	console.log(capsInPipeline)

	if (capsInPipeline.length > 0) {
		return (
			<>
				<table className="table table-dark">
					<thead>
						<tr>
							<th scope="col">#</th>
							<th scope="col">Capture ID</th>
							<th scope="col">Site</th>
							<th scope="col">Captured</th>
							<th scope="col">Set State</th>
							<th scope="col">QC'd</th>
							<th scope="col">QC User</th>

							{capsInPipeline[0].hasOwnProperty('qc_date') && (
								<>
									{capsInPipeline[0].qc_date !== null ? (
										<th scope="col">QC Date</th>
									) : (
										<th scope="col"></th>
									)}
									{capsInPipeline[0].qc_date !== null ? (
										<th scope="col">QC Time</th>
									) : (
										<th scope="col"></th>
									)}
								</>
							)}
							{postQCPerformed !== undefined && (
								<th scope="col">Post-QC performed</th>
							)}
							{uploaded !== undefined && <th scope="col">Uploaded</th>}
							{uploadedToDev !== undefined && (
								<th scope="col">Uploaded (dev)</th>
							)}
							{uploadedToDev !== undefined && (
								<th scope="col">Uploaded (prod)</th>
							)}
						</tr>
					</thead>

					{capsInPipeline.length > 0 && (
						<tbody>
							{capsInPipeline.map((capture, idx) => (
								<tr key={idx}>
									{currentCaptureIndex === idx ? (
										<>
											<th scope="row" className="table-secondary">
												{idx + 1}
											</th>

											<td className="table-secondary">{capture.id}</td>
											<td className="table-secondary">{capture.site_name}</td>
											<td className="table-secondary">{capture.captured}</td>

											{capture.state === null ? (
												<td className="table-secondary text-success"></td>
											) : capture.state === 1 ? (
												<td className="table-secondary text-success">
													accepted
												</td>
											) : (
												<td className="table-secondary text-danger">
													rejected
												</td>
											)}

											{capture['state'] != null ? (
												<td className="table-secondary">
													<i class="fa-regular fa-circle-check"></i>
												</td>
											) : (
												<td className="table-secondary">
													{/* <i class="fa-regular fa-circle-xmark"></i> */}
												</td>
											)}
											<td className="table-secondary">{`${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`}</td>

											{capture.qc_date !== null ? (
												<td className="table-secondary">
													{capture['qc_date'].slice(0, 10)}
												</td>
											) : (
												<td className="table-secondary"></td>
											)}

											{capture.qc_date !== null ? (
												<td className="table-secondary">
													{capture['qc_date'].slice(10, 16)}
												</td>
											) : (
												<td className="table-secondary"></td>
											)}

											{postQCPerformed !== undefined && (
												<>
													{postQCPerformed && capture['state'] !== null ? (
														<td className="table-secondary text-center">
															<i class="fa-regular fa-circle-check"></i>
														</td>
													) : (
														<td className="table-secondary"></td>
													)}
												</>
											)}
											{uploaded !== undefined && (
												<>
													{uploaded && capture['state'] !== null ? (
														<td className="table-secondary text-center">
															<i class="fa-regular fa-circle-check"></i>
														</td>
													) : (
														<td className="table-secondary"></td>
													)}
												</>
											)}

											{uploadedToDev !== undefined && (
												<>
													{uploadedToDev && capture['state'] !== null ? (
														<td className="table-secondary text-center">
															<i class="fa-regular fa-circle-check"></i>
														</td>
													) : (
														<td className="table-secondary"></td>
													)}
												</>
											)}

											{uploadedToProd !== undefined && (
												<>
													{uploadedToProd && capture['state'] !== null ? (
														<td className="table-secondary text-center">
															<i class="fa-regular fa-circle-check"></i>
														</td>
													) : (
														<td className="table-secondary"></td>
													)}
												</>
											)}
										</>
									) : (
										<>
											<th scope="row">{idx + 1}</th>

											{allowJumpCapture ? (
												<td
													data-bs-dismiss="modal"
													onClick={(e) => setCurrentCaptureIndex(idx)}
													style={{
														color: 'blue',
														textDecoration: 'underline',
														cursor: 'pointer'
													}}
													// onClick={(e) => openThisCapture(e, idx)}
													//onClick={(e) => sentDataToParent(e, idx)}
													data-tooltip-id="jumpToCaptureQCing"
												>
													{capture.id}
												</td>
											) : (
												<td data-bs-dismiss="modal">{capture.id}</td>
											)}

											<td>{capture.site_name}</td>
											<td>{capture.captured}</td>

											{capture.state === null ? (
												<td className="text-success"></td>
											) : capture.state === 1 ? (
												<td className="text-success">accepted</td>
											) : (
												<td className="text-danger">rejected</td>
											)}

											{capture['state'] != null ? (
												<td>
													<i class="fa-regular fa-circle-check"></i>
												</td>
											) : (
												<td>
													{/* <i class="fa-regular fa-circle-xmark"></i> */}
												</td>
											)}
											<td>{`${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`}</td>

											{capture.qc_date !== null ? (
												<td>{capture['qc_date'].slice(0, 10)}</td>
											) : (
												<td></td>
											)}

											{capture.qc_date !== null ? (
												<td>{capture['qc_date'].slice(10, 16)}</td>
											) : (
												<td></td>
											)}

											{postQCPerformed !== undefined && (
												<>
													{postQCPerformed && capture['state'] !== null ? (
														<td className="text-center">
															<i class="fa-regular fa-circle-check"></i>
														</td>
													) : (
														<td></td>
													)}
												</>
											)}
											{uploaded !== undefined && (
												<>
													{uploaded && capture['state'] !== null ? (
														<td className="text-center">
															<i class="fa-regular fa-circle-check"></i>
														</td>
													) : (
														<td></td>
													)}
												</>
											)}

											{uploadedToDev !== undefined && (
												<>
													{uploadedToDev && capture['state'] !== null ? (
														<td className=" text-center">
															<i class="fa-regular fa-circle-check"></i>
														</td>
													) : (
														<td></td>
													)}
												</>
											)}

											{uploadedToProd !== undefined && (
												<>
													{uploadedToProd && capture['state'] !== null ? (
														<td className="text-center">
															<i class="fa-regular fa-circle-check"></i>
														</td>
													) : (
														<td></td>
													)}
												</>
											)}
										</>
									)}
								</tr>
							))}
						</tbody>
					)}
				</table>

				<Tooltip id="jumpToCaptureQCing">Jump to this capture</Tooltip>
			</>
		);
	}
};

export default StockpilesTableBasic;
