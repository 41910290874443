import React, { useContext } from 'react';
import { Tooltip } from 'react-tooltip';
import { pipelineContext } from '../../contexts/pipelineContext';
import { useNavigate } from 'react-router-dom';

const QCProgressBar = () => {
	const { qcInProgress, progressBar, comm, qcType, qcPerc, capsInPipeline, currentCaptureIndex, resetState } =
		useContext(pipelineContext);

	const navigate = useNavigate();

	const navigateToHome = () => {

		if (progressBar.title === "QC Report" && !qcInProgress) {
			resetState()
		}

		document.body.style.cursor = 'wait';

		setTimeout(() => {
			document.body.style.cursor = 'default';
			navigate('/');
		}, 2500);
	};


	return (
		<div className="d-flex justify-content-evenly mt-2 my-md-2">
			<div className="progress-bar-container d-flex align-items-center">
				<div className="progress-bar-abs-div">
					<div className="progress">
						<div
							id="QCprogressbar"
							className={`progress-bar ${
								qcInProgress ? 'progress-bar-striped progress-bar-animated' : ''
							}`}
							role="progressbar"
							style={{ width: `${progressBar.width}%` }}
							aria-valuenow="50"
							aria-valuemin="0"
							aria-valuemax="100"
						></div>
					</div>
				</div>

				<div className="progress-bar-div-2">
					<div className="row pt-1" style={{ height: '100%' }}>
						{comm === undefined ? (
							<div className="col" data-tooltip-id="progress-bar-qc">
								<p className="text-center text-white progressBarText">
									QC Site Type
								</p>
							</div>
						) : comm === 'smelters' ? (
							<div className="col" data-tooltip-id="progress-bar-qc">
								<p className="text-center text-white progressBarText">
									{comm.charAt(0).toUpperCase() + comm.slice(1)}{' '}
									<i className="fa-solid fa-city ps-2"></i>
								</p>
							</div>
						) : comm === 'stockpiles' ? (
							<div className="col" data-tooltip-id="progress-bar-qc">
								<p className="text-center text-white progressBarText">
									{comm.charAt(0).toUpperCase() + comm.slice(1)}{' '}
									<i className="fa-solid fa-city ps-2"></i>
								</p>
							</div>
						) : (
							<div className="col" data-tooltip-id="progress-bar-qc">
								<p className="text-center text-white progressBarText">
									{comm.charAt(0).toUpperCase() + comm.slice(1)}{' '}
									<i className="fa-solid fa-tower-broadcast ps-3"></i>
								</p>
							</div>
						)}

						{qcType === undefined ? (
							<div className="col" data-tooltip-id="progress-bar-qc">
								<p className="text-center text-white progressBarText">
									QC Type
								</p>
							</div>
						) : qcType === 'Daily' ? (
							<div className="col" data-tooltip-id="progress-bar-qc">
								<p className="text-center text-white progressBarText">
									Daily <i className="fa-solid fa-calendar-day ps-2"></i>
								</p>
							</div>
						) : qcType === 'Historic' ? (
							<div className="col" data-tooltip-id="progress-bar-qc">
								<p className="text-center text-white progressBarText">
									Historic <i className="fa-solid fa-calendar-days ps-2"></i>
								</p>
							</div>
						) : (
							<div className="col" data-tooltip-id="progress-bar-qc">
								<p className="text-center text-white progressBarText">
									Re-QC <i className="fa-solid fa-calendar-check ps-2"></i>
								</p>
							</div>
						)}

						{qcType === undefined ? (
							<div className="col" data-tooltip-id="progress-bar-qc">
								<p className="text-center text-white progressBarText">
									QC Form
								</p>
							</div>
						) : qcType === 'Historic' ? (
							<div className="col" data-tooltip-id="progress-bar-qc">
								<p className="text-center text-white progressBarText">
									Historic QC Form
								</p>
							</div>
						) : qcType === 'Daily' ? (
							<div className="col" data-tooltip-id="progress-bar-qc">
								<p className="text-center text-white progressBarText">
									Daily QC Form
								</p>
							</div>
						) : (
							<></>
						)}

						<div className="col" data-tooltip-id="progress-bar-qc">
							{capsInPipeline.length > 0 ? (
								<p className="text-center text-white progressBarText">
									View captures ({capsInPipeline.length})
								</p>
							) : (
								<p className="text-center text-white progressBarText">
									View captures
								</p>
							)}
						</div>
						<div className="col" data-tooltip-id="progress-bar-qc">
							<p className="text-center text-white progressBarText">
								Perform QC ({qcPerc}%)
							</p>
						</div>
						<div className="col" data-tooltip-id="progress-bar-qc">
							<p className="text-center text-white progressBarText">
								Upload captures
							</p>
						</div>
						<div className="col" data-tooltip-id="progress-bar-qc">
							<p className="text-center text-white progressBarText">
								QC report
							</p>
						</div>
					</div>
				</div>
			</div>

			<Tooltip id="progress-bar-qc" place="bottom" effect="solid">
				<div className="p-1">
					<table>
						<tbody>
							{qcInProgress ? (
								<tr>
									<td>QC in progress</td>
									<td className="d-flex justify-content-end">
										<div className="flashing-dot ms-3 mt-1"></div>
									</td>
								</tr>
							) : (
								<tr>
									<td>QC not in progress</td>
									<td className="d-flex justify-content-end">
										<div className="not-flashing-dot ms-3 mt-1"></div>
									</td>
								</tr>
							)}

							{progressBar.width === 1 * (100 / 7) ? (
								<>
									<tr>
										<td>Current page</td>
										<td className="text-end">QC Site Type</td>
									</tr>

									<tr>
										<td>Next page</td>
										<td className="text-end">QC Type</td>
									</tr>
								</>
							) : progressBar.width === 2 * (100 / 7) ? (
								<>
									<tr>
										<td>Current page</td>
										<td className="text-end">QC Type</td>
									</tr>

									<tr>
										<td>Next page</td>
										<td className="text-end">QC Form</td>
									</tr>
								</>
							) : progressBar.width === 3 * (100 / 7) ? (
								<>
									<tr>
										<td>Current page</td>
										<td className="text-end">QC Form</td>
									</tr>

									<tr>
										<td>Next page</td>
										<td className="text-end">View Captures</td>
									</tr>
								</>
							) : progressBar.width === 4 * (100 / 7) ? (
								<>
									<tr>
										<td>Current page</td>
										<td className="text-end">View Captures</td>
									</tr>

									<tr>
										<td>Next page</td>
										<td className="text-end">Perform QC</td>
									</tr>
								</>
							) : progressBar.width === 5 * (100 / 7) ? (
								<>
									<tr>
										<td>Current page</td>
										<td className="text-end">Perform QC</td>
									</tr>

									<tr>
										<td>Next page</td>
										<td className="text-end">QC Upload</td>
									</tr>
								</>
							) : progressBar.width === 6 * (100 / 7) ? (
								<>
									<tr>
										<td>Current page</td>
										<td className="text-end">QC Upload</td>
									</tr>

									<tr>
										<td>Next page</td>
										<td className="text-end">QC Report</td>
									</tr>
								</>
							) : (
								progressBar.width === 7 * (100 / 7) && (
									<>
										<tr>
											<td>Current page</td>
											<td className="text-end">QC Report</td>
										</tr>

										<tr>
											<td>Next page</td>
											<td className="text-end">-</td>
										</tr>
									</>
								)
							)}

							<tr>
								<td>Captures in queue</td>
								<td className="text-end">{capsInPipeline.length}</td>
							</tr>

							{progressBar.width === 5 * (100 / 7) && <tr>
								<td>Current capture</td>
								<td className="text-end">{currentCaptureIndex + 1}</td>
							</tr>}
							

							<tr>
								<td>Captures QC'd</td>
								<td className="text-end">{qcPerc}%</td>
							</tr>
						</tbody>
					</table>
				</div>
			</Tooltip>

			<div
				className="d-flex justify-content-center"
				style={{
					width: '10%'
					// marginTop: '12px',
				}}
			>
				{qcInProgress && progressBar.title !== 'Upload Captures' ? (
					<>
						<button
							style={{
								width: '70%',
								height: '35px',
								textAlign: 'center',
								padding: '2px'
							}}
							data-bs-toggle="modal"
							data-bs-target="#exitQCModal"
							className="btn btn-secondary"
							data-tooltip-id="exitQCButton"
						>
							Exit QC
						</button>
						<Tooltip id="exitQCButton" place="bottom" effect="solid">
							You will review your current captures
						</Tooltip>
					</>
				) : capsInPipeline.length === 0 || progressBar.title === "QC Report" ? (
					<>
						<button
							onClick={() => navigateToHome()}
							style={{
								width: '70%',
								height: '35px',
								textAlign: 'center',
								padding: '2px'
							}}
							className="btn btn-secondary"
							data-tooltip-id="exitQCButtonDirectly"
						>
							Exit QC
						</button>
						<Tooltip id="exitQCButtonDirectly" place="bottom" effect="solid">
							You will be navigated back to the homepage
						</Tooltip>
					</>
				)  : (
					<>
						<button
							onClick={(e) => e.preventDefault()}
							style={{
								width: '70%',
								height: '35px',
								textAlign: 'center',
								padding: '2px'
							}}
							className="btn btn-secondary"
							data-tooltip-id="cannotExitQC"
						>
							Exit QC
						</button>
						<Tooltip id="cannotExitQC" place="bottom" effect="solid">
							You cannot exit QC at this stage
						</Tooltip>
					</>
				)}
			</div>
		</div>
	);
};

export default QCProgressBar;
