import React from 'react';
import { Tooltip } from 'react-tooltip';
import config from '../config';
import formatDate from '../helpers/formatDate';

const CapturesTable = ({
	fields,
	captures,
	qcCat,
	currentCaptureIndex,
	tableHeight
}) => {
	const getCellStyle = (capture, field, currentCaptureIndex) => {
		let classname = '';

		if (qcCat === 'smelters' && field.data === 'state') {
			if (capture.state === 0) {
				classname = 'fw-bold inactiveCap';
			} else if (capture.state === 1) {
				classname = 'fw-bold activeCap';
			} else {
				classname = 'fw-bold invalidCap';
			}
		}

		if (qcCat === 'stockpiles' && field.data === 'state') {
			if (capture.state === 0) {
				classname = 'fw-bold text-success';
			} else if (capture.state === 1) {
				classname = 'fw-bold text-danger';
			} else if (capture.state === null) {
				classname = '';
			}
		}

		if (field.hasOwnProperty('link')) {
			classname = 'text-decoration-underline';
		}

		if (currentCaptureIndex) {
			classname = `fw-bold ${classname}`;
		}

		return classname;
	};

	const getCellContent = (capture, field, currentCaptureIndex) => {
		const fieldData = capture[field.data] ?? '';

		if (qcCat === 'smelters' && field.data === 'state') {
			return config.qcStates.filter(
				(qstate) => qstate.stateInt === capture.state
			)[0].stateString;
		}

		if (qcCat === 'stockpiles' && field.data === 'state') {
			return capture.state === 1
				? 'Accepted'
				: capture.state === 0
				? 'Rejected'
				: '';
		}

		if (field.data === 'qc_state') {
			return capture.qc_state === 3 ? (
				<i class="fa-regular fa-circle-xmark"></i>
			) : capture.qc_state === 4 ? (
				<i class="fa-regular fa-circle-xmark"></i>
			) : (
				<i class="fa-regular fa-circle-check"></i>
			);
		}

		if (
			field.data === 'post_qc' ||
			field.data === 'upload_to_dev' ||
			field.data ===
			'upload_to_prod'
		) {
			return <i class="fa-regular fa-circle-xmark"></i>;
		}

		if (field.data === 'captured' && field.header === 'Capture Date') {
			try {
				let date = formatDate(capture.captured.slice(0, 10));
				return date;
			} catch {
				return '-';
			}
		}

		// ISSUE HERE
		if (field.data === 'captured' && field.header === 'Capture Time') {
			return capture.captured.slice(11, 16);
		}

		if (field.Data === null) {
			return '-';
		}

		if (fieldData.length > 15) {
			return fieldData.slice(0, 15) + '...';
		} else {
			return fieldData;
		}

		// }
	};

	const getHeaderContent = (field) => {
		if (field.header === 'ID' && field.hasOwnProperty('link')) {
			return (
				<>
					{field.header} <i className="ps-2 fa-solid fa-arrow-pointer"></i>
				</>
			);
		} else {
			return field.header;
		}
	};

	const getCellLink = (field) => {
		if (field.hasOwnProperty('link')) {
			return field.link;
		}
	};

	const getCursor = (field) => {
		if (field.hasOwnProperty('link')) {
			return 'pointer';
		}
	};

	const getModalState = (field) => {
		if (field.hasOwnProperty('link')) {
			return 'modal';
		}
	};

	const getClickableTooltip = (field) => {
		if (field.header === 'ID' && field.hasOwnProperty('link')) {
			return 'clickableTooltip';
		} else {
			return undefined;
		}
	};

	const openThisCapture = (e, idx) => {
		console.log(idx);
	};


	return (
		<>
			<div
				className="table-responsive"
				style={{ maxHeight: tableHeight ? tableHeight : null }}
			>
				<table className="captureTable">
					<thead className="thead-dark">
						<tr>
							<th style={{ width: '30px' }}></th>
							{fields.map((field, idx) => (
								<th
									key={idx}
									scope="col"
									data-tooltip-id={getClickableTooltip(field)}
								>
									{getHeaderContent(field)}
								</th>
							))}
						</tr>
					</thead>
					<tbody className="table-group-divider">
						{captures && captures.length > 0 ? (
							<>
								{captures.map((capture, idx) => (
									<tr
										data-tooltip-id={
											currentCaptureIndex === idx && 'currentCaptureTT'
										}
										key={idx}
									>
										<td style={{ width: '30px' }}>
											{currentCaptureIndex === idx && (
												<div className="captureTableCurrentCapDot"></div>
											)}
										</td>
										{fields.map((field, fIdx) => (
											<td
												className={getCellStyle(capture, field)}
												data-bs-dismiss={getModalState(field)}
												key={fIdx}
												href={getCellLink(field)}
												style={{ cursor: getCursor(field), width: '200px' }}
												onClick={
													field?.link
														? (e) => openThisCapture(e, idx)
														: undefined
												}
											>
												{getCellContent(
													capture,
													field,
													currentCaptureIndex === idx ? true : false
												)}
											</td>
										))}
									</tr>
								))}
							</>
						) : (
							<tr>
								<td style={{ width: '200px' }}>-</td>
								{fields.map((field, fIdx) => (
									<td style={{ width: '200px' }} key={fIdx}>
										-
									</td>
								))}
							</tr>
						)}
					</tbody>
				</table>
				<Tooltip id="currentCaptureTT">Current capture</Tooltip>
				<Tooltip id="clickableTooltip">
					Click on the capture ID to navigate to capture in QC
				</Tooltip>
			</div>
		</>
	);
};

export default CapturesTable;
