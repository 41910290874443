import './App.css';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { IdleTimerProvider } from 'react-idle-timer';
import { Auth } from 'aws-amplify';
import Routes from './routes';
import {
	getAuthenticatedUserGroups,
	getAuthenticatedUserAttributes
} from './actions/authUtils';
import config from './config';
import { Toaster } from 'react-hot-toast';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { EndpointProvider } from './contexts/endpointContext';
import { PipelineProvider, pipelineContext } from './contexts/pipelineContext';
import exportConfig from './config';
import useGainsQCApi from './services/useGainsQCApi';
import { Tooltip } from 'react-tooltip';

export default function App() {
	const [environment, setEnvironment] = useState(exportConfig.gainsEnvironment);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isAuthenticating, setIsAuthenticating] = useState(false);
	const [authGroups, setAuthGroups] = useState([]);
	const [userAttributes, setUserAttributes] = useState({});
	const [showWarning, setShowWarning] = useState(null);
	const [logoutTimer, setLogoutTimer] = useState(null);
	const idleTimerRef = useRef(null);
	const [remainingTime, setRemainingTime] = useState(null);
	const idleTimeout = exportConfig.idleTimeout;

	const [loggedInUsers, setLoggedInUsers] = useState([]);

	const pipelineContextApp = useContext(pipelineContext);

	const { error: errorSendingCapsBack, makeRequest: sendCapsBack } =
		useGainsQCApi();

	useEffect(() => {
		setShowWarning(false);
		const interval = setInterval(() => {
			if (idleTimerRef.current) {
				const remaining = idleTimerRef.current.getRemainingTime();
				console.log(remaining);
				setRemainingTime(remaining > 0 ? remaining : 0); // Ensure remaining time is not negative
			}
		}, 1000); // Update every second

		return () => clearInterval(interval); // Cleanup interval
	}, []);

	const handleOnActive = () => {
		console.log('active');
	};

	const onIdle = () => {
		if (isAuthenticated) {
			setShowWarning(true);
			setLogoutTimer(setTimeout(logoutUser, 1000 * 60 * 5));
		}
	};

	const handleCloseModal = () => {
		setLogoutTimer(null);
		clearTimeout(logoutTimer);
		setShowWarning(false);
	};

	const userHasAuthenticated = (isAuthenticated, authGroups, userEmail) => {
		localStorage.setItem('authenticatedUser', userEmail);
		pipelineContextApp.resetState();

		setIsAuthenticated(isAuthenticated);
		setAuthGroups(authGroups);
	};

	useEffect(() => {
		const loginUser = async () => {
			try {
				const { idToken } = await Auth.currentSession();

				if (idToken) {
					const { authenticatedUserGroups: authGroups } =
						await getAuthenticatedUserGroups();

					getUserAttributes().catch(console.error);
				}
			} catch (e) {
				if (e !== 'No current user') {
					console.error('Unexpected error in App:componentDidMount', e);
				}
			}
		};

		const getUserAttributes = async () => {
			const userAttributesAuth = await getAuthenticatedUserAttributes();

			// console.log(userAttributesAuth)
			// const { authenticatedUserGroups: authGroups } =
			// 	await getAuthenticatedUserGroups();

			if (userAttributesAuth) {
				setUserAttributes({
					givenName: userAttributesAuth.givenName,
					familyName: userAttributesAuth.familyName,
					email: userAttributesAuth.email,
					initials: userAttributesAuth.initials
				});

				userHasAuthenticated(true, authGroups);
			}
		};

		pipelineContextApp.resetState();
		loginUser().catch(console.error);
	}, []);

	const authProps = {
		isAuthenticated: isAuthenticated,
		isAuthenticating: isAuthenticating,
		setIsAuthenticating: setIsAuthenticating,
		userHasAuthenticated: userHasAuthenticated,
		authGroups: authGroups,
		userAttributes: userAttributes,
		setUserAttributes: setUserAttributes,
		environment: environment,
		setEnvironment: setEnvironment
	};

	const logoutUser = async () => {
		if (pipelineContextApp.capsInPipeline.length > 0) {
			let caps = pipelineContextApp.capsInPipeline.map((cap) => cap.id);
			let commodity = [
				exportConfig.commodities.filter(
					(comm) => comm.id === pipelineContextApp.comm
				)[0].index
			];
			let user = userAttributes;

			await sendCapsBack(
				`${exportConfig.baseUrl}/${pipelineContextApp.comm}/remove_from_queue`,
				'POST',
				{
					commodityIds: `${commodity.join(',')}`,
					qcUser: `${user.givenName.toLowerCase()}.${user.familyName.toLowerCase()}`,
					captureIds: caps.join(',')
				},
				'sending caps back'
			);
		}

		console.log('logging user out automatically');

		await pipelineContextApp.resetState();
		setShowWarning(false);

		await Auth.signOut();
		setIsAuthenticated(false);
		setIsAuthenticating(false);
		setAuthGroups([]);
	};

	return (
		<div id="appContainer">
			{isAuthenticated && <Toaster />}

			<EndpointProvider env={environment}>
				<PipelineProvider>
					<IdleTimerProvider
						ref={idleTimerRef}
						element={document}
						onIdle={onIdle}
						timeout={idleTimeout}
						onActive={handleOnActive}
					>
						<Routes authProps={authProps} />
					</IdleTimerProvider>
				</PipelineProvider>
			</EndpointProvider>

			<div>
				<Modal
					style={{ marginTop: '80px' }}
					isOpen={showWarning}
					onHide={handleCloseModal}
				>
					<ModalHeader
						style={{ backgroundColor: 'rgb(48, 48, 48)' }}
						className="text-light"
						closeButton
					>
						Session inactive
					</ModalHeader>
					<ModalBody>
						<p>Your session will expire in 5 minutes due to inactivity.</p>
					</ModalBody>
					<ModalFooter>
						<Button variant="secondary" onClick={logoutUser}>
							<i className="fas fa-fw fa-sign-out-alt mr-1" /> Logout
						</Button>
						<Button variant="secondary" onClick={handleCloseModal}>
							Close
						</Button>
					</ModalFooter>
				</Modal>
			</div>

			<Tooltip id="environment" place="top" effect="solid">
				You are currently working in the{' '}
				<span className="text-primary fw-bold">
					{exportConfig.gainsEnvironment.toUpperCase()}
				</span>{' '}
				environment
			</Tooltip>
		</div>
	);
}
