import React, { createContext, useState, useEffect, useCallback } from 'react';

const pipelineContext = createContext();

const PipelineProvider = ({ children }) => {
	/**
	 * useContext: a way to share data between components without having to pass props manually at each level
	 * avoids 'prop drilling'
	 * ensures all components have access to the pipeline variables
	 */

	const initialState = {
		capsInPipeline: [],
		comm: undefined,
		qcType: undefined,
		qcPerc: 0,
		progressBar: {
			width: 0,
			title: ''
		},
		qcInProgress: false,
		postQCPerformed: false,
		uploadPerformed: false,
		currentCaptureIndex: 0,
		straightToQC: false,
		straightToUpload: false,
		loading: false,
	};

	const [capsInPipeline, setCapsInPipeline] = useState([]);
	const [comm, setComm] = useState();
	const [qcType, setQCType] = useState();
	const [qcPerc, setQCPerc] = useState(0);
	const [progressBar, setProgressBar] = useState({
		width: 0,
		title: ''
	});
	const [qcInProgress, setQCInProgress] = useState(false);
	const [onQCPage, setOnQCPage] = useState(false);
	const [postQCPerformed, setPostQCPerformed] = useState(false);
	const [uploadPerformed, setUploadPerformed] = useState(false);
	const [currentCaptureIndex, setCurrentCaptureIndex] = useState(0);
	const [straightToQC, setStraightToQC] = useState(false);
	const [straightToUpload, setStraightToUpload] = useState(false);
	const [loading, setLoading] = useState(false);
	const [navigateHome, setNavigateHome] = useState(false)

	const resetState = () => {
		console.log('pipeline context resetting state');
		setCapsInPipeline(initialState.capsInPipeline);
		setComm(initialState.comm);
		setQCType(initialState.qcType);
		setQCPerc(initialState.qcPerc);
		setProgressBar(initialState.progressBar);
		setQCInProgress(initialState.qcInProgress);
		setPostQCPerformed(initialState.postQCPerformed);
		setUploadPerformed(initialState.uploadPerformed);
		setCurrentCaptureIndex(initialState.currentCaptureIndex);
		setStraightToQC(initialState.straightToQC);
		setStraightToUpload(initialState.straightToUpload);
		setLoading(initialState.loading);
		//setNavigateHome(true)
	};

	return (
		<pipelineContext.Provider
			value={{
				capsInPipeline,
				setCapsInPipeline,
				comm,
				setComm,
				qcType,
				setQCType,
				qcPerc,
				setQCPerc,
				progressBar,
				setProgressBar,
				qcInProgress,
				setQCInProgress,
				onQCPage,
				setOnQCPage,
				postQCPerformed,
				setPostQCPerformed,
				uploadPerformed,
				setUploadPerformed,
				currentCaptureIndex,
				setCurrentCaptureIndex,
				straightToQC,
				setStraightToQC,
				straightToUpload,
				setStraightToUpload,
				navigateHome,
				setNavigateHome,
				resetState,
				loading,
				setLoading,
			}}
		>
			{children}
		</pipelineContext.Provider>
	);
};

export { pipelineContext, PipelineProvider };
