import React, { useContext } from 'react';
import { Row, div } from 'reactstrap';
import { Tooltip } from 'react-tooltip';
import { pipelineContext } from '../../../contexts/pipelineContext';

const QCType = ({ loading }) => {
	const pipelineContextQCType = useContext(pipelineContext);

	const handleSetQCType = (qc_type) => {
		pipelineContextQCType.setQCType(qc_type);
		pipelineContextQCType.setProgressBar({
			width: 3 * (100 / 7),
			title: qc_type
		});
	};

	const handleBackToQCCat = () => {
		pipelineContextQCType.setComm(undefined);
		pipelineContextQCType.setProgressBar({
			width: 1 * (100 / 7),
			title: ''
		});
	};

	console.log(loading);

	return (
		<>
			<hr></hr>
			<div className="px-3 mx-3">
				<div className="mt-2 d-flex flex-row align-items-center">
					<button
						className="btn interactButtonSecondary backButton"
						onClick={handleBackToQCCat}
						style={{ width: '200px', fontSize: '15px' }}
					>
						<i class="fa-solid fa-left-long pe-3"></i>
						Change site type
					</button>
					<h1 className="ms-5 mt-2">QC</h1>
				</div>

				<h4 className="py-3 py-md-3">
						Select the type of QC to perform for{' '}
						<strong>{pipelineContextQCType.comm}</strong>
					</h4>

				<Row className="d-flex justify-content-center justify-content-md-start pt-5">
					

					{pipelineContext.loading ? (
						<>
							<div className="col-3">
								<button style={{ border: 'none', backgroundColor: '#f8f9fa' }}>
									<div
										className="card QCTypeCard"
										style={{
											width: '100%',
											height: '130px',
											backgroundColor: '#f8f9fa',
											color: 'grey'
										}}
									>
										<div className="card-body">
											<h5 className="card-title">
												Daily QC{' '}
												<i className="fa fa-solid fa-calendar-day ps-3"></i>
											</h5>
											<p className="card-text">
												Perform a routine QC on images captured in the last day
											</p>
										</div>
									</div>
								</button>
							</div>
							<div className="col-3">
								<button style={{ border: 'none', backgroundColor: '#f8f9fa' }}>
									<div
										className="card QCTypeCard"
										style={{
											width: '100%',
											height: '120px',
											backgroundColor: '#f8f9fa',
											color: 'grey'
										}}
									>
										<div className="card-body">
											<h5 className="card-title">
												Historic QC{' '}
												<i className="fa fa-solid fa-calendar-days ps-3"></i>
											</h5>
											<p className="card-text">
												Perform a historic QC only on captures from a particular
												site
											</p>
										</div>
									</div>
								</button>
							</div>
							<div className="col-3">
								<button
									style={{
										border: 'none',
										backgroundColor: '#f8f9fa',
										color: 'grey'
									}}
								>
									<div
										className="card QCTypeCard"
										style={{ width: '100%', height: '130px', backgroundColor: '#f7f7f7' }}
									>
										<div className="card-body">
											<h5 className="card-title">
												Re-QC{' '}
												<i className="fa fa-solid fa-calendar-check ps-3"></i>
											</h5>
											<p className="card-text">
												Re-QC previously QC'd captures
											</p>
										</div>
									</div>
								</button>
							</div>
						</>
					) : (
						<>
							<div className="col-10 col-sm-6 col-md-6 col-lg-4 col-xl-3 my-2 my-lg-0">
								<button
									onClick={(e) => handleSetQCType('Daily')}
									className="QCTypeButtons"
								>
									<div
										className="card QCTypeButtonsBtn"
										style={{ width: '100%', height: '130px' }}
									>
										<div className="card-body">
											<h5 className="card-title">
												Daily QC{' '}
												<i className="fa fa-solid fa-calendar-day ps-3"></i>
											</h5>
											<p className="card-text">
												Perform a routine QC on images captured in the last day
											</p>
										</div>
									</div>
								</button>
							</div>
							<div className="col-10 col-sm-6 col-md-6 col-lg-4 col-xl-3 my-2 my-lg-0">
								<button
									onClick={(e) => handleSetQCType('Historic')}
									className="QCTypeButtons"
								>
									<div
										className="card QCTypeButtonsBtn"
										style={{ width: '100%', height: '130px' }}
									>
										<div className="card-body ">
											<h5 className="card-title">
												Historic QC{' '}
												<i className="fa fa-solid fa-calendar-days ps-3"></i>
											</h5>
											<p className="card-text">
												Perform a historic QC only on captures from a particular
												site
											</p>
										</div>
									</div>
								</button>
							</div>
							<div className="col-10 col-sm-6 col-md-6 col-lg-4 col-xl-3 my-2 my-lg-0">
								<button
									//onClick={(e) => handleSetQCType('Re QC Form')}
									data-tooltip-id="reQCType"
									className="QCTypeButtons"
								>
									<div
										className="card QCTypeButtonsBtn"
										style={{ width: '100%', height: '130px', backgroundColor: '#f7f7f7' }}
									>
										<div className="card-body ">
											<h5 className="card-title">
												Re-QC{' '}
												<i className="fa fa-solid fa-calendar-check ps-3"></i>
											</h5>
											<p className="card-text">
												Re-QC previously QC'd captures
											</p>
										</div>
									</div>
								</button>

								<Tooltip
									id="reQCType"
									place="bottom"
									effect="solid"
									style={{ backgroundColor: 'grey' }}
								>
									The option to Re-QC is not currently available
								</Tooltip>
							</div>
						</>
					)}
				</Row>
			</div>
		</>
	);
};

export default QCType;
