import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Tooltip } from 'react-tooltip';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Legend,
	TimeScale,
	registerables
} from 'chart.js';
import { ClipLoader } from 'react-spinners';
import exportConfig from '../../../config';

import ReactSlider from 'react-slider';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	
	Legend,
	...registerables
);

const Barplot = ({
	plotData,
	captureDate,
	plotDataError,
	plotDataLoading,
	fetchBarplotData,
	loading
}) => {
	const [data, setData] = useState(plotData);
	const [dataTop, setDataTop] = useState();
	const [dataBottom, setDataBottom] = useState();
	const [totalDays, setTotalDays] = useState();
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(30);
	const [xAxisFormat, setXAxisFormat] = useState({
		ticks: {
			font: {
			  size: 10,
			},
			
		  },
		type: 'time',
		time: {
			unit: 'day',
			tooltipFormat: 'dd MMM yy',
			displayFormats: {
				month: 'MMM yy'
			}
		},
		stacked: true,
		grid: {
			display: false
		},
		offset: false,
		barPercentage: 1.0,
		categoryPercentage: 1.0
	});

	const handleSliderChange = (values) => {
		setStartIndex(values[0]);
		setEndIndex(values[1]);

		if (values[1] - values[0] <= 30) {
			setXAxisFormat({
				type: 'time',
				time: {
					unit: 'day',
					tooltipFormat: 'MMM dd',
					displayFormats: {
						month: 'MMM dd'
					}
				},
				stacked: true,
				grid: {
					display: false
				},
				offset: false,
				barPercentage: 1.0,
				categoryPercentage: 1.0
			});
		} else if (values[1] - values[0] >= 30 && values[1] - values[0] <= 150) {
			setXAxisFormat({
				type: 'time',
				time: {
					unit: 'week',
					tooltipFormat: 'MMM dd',
					displayFormats: {
						month: 'MMM dd'
					}
				},
				stacked: true,
				grid: {
					display: false
				},
				offset: false,
				barPercentage: 1.0,
				categoryPercentage: 1.0
			});
		} else {
			setXAxisFormat({
				type: 'time',
				time: {
					unit: 'month',
					tooltipFormat: 'MMM yy',
					displayFormats: {
						month: 'MMM yy'
					}
				},
				stacked: true,
				grid: {
					display: false
				},
				offset: false,
				barPercentage: 1.0,
				categoryPercentage: 1.0
			});
		}

		let setsOfData = [];

		if ('invalid' in data) {
			let obj = {
				label: 'invalid',
				backgroundColor: 'darkgray',
				borderColor: 'darkgray',
				borderWidth: 0,
				order: 2,
				data: data['invalid'].slice(values[0], values[1])
			};
			setsOfData.push(obj);
		}

		if ('qc-invalid' in data) {
			let obj = {
				label: 'qc-invalid',
				backgroundColor: 'gray',
				borderColor: 'gray',
				borderWidth: 0,
				order: 2,
				data: data['qc-invalid'].slice(values[0], values[1])
			};
			setsOfData.push(obj);
		}

		if ('inactive' in data) {
			let obj = {
				label: 'inactive',
				backgroundColor: 'royalblue',
				borderColor: 'royalblue',
				borderWidth: 0,
				order: 2,
				data: data['inactive'].slice(values[0], values[1])
			};
			setsOfData.push(obj);
		}

		if ('qc-inactive' in data) {
			let obj = {
				label: 'qc-inactive',
				backgroundColor: 'blue',
				borderColor: 'blue',
				borderWidth: 0,
				order: 2,
				data: data['qc-inactive'].slice(values[0], values[1])
			};
			setsOfData.push(obj);
		}

		if ('active' in data) {
			let obj = {
				label: 'active',
				backgroundColor: 'goldenrod',
				borderColor: 'goldenrod',
				borderWidth: 0,
				order: 2,

				data: data['active'].slice(values[0], values[1])
			};
			setsOfData.push(obj);
		}

		if ('qc-active' in data) {
			let obj = {
				label: 'qc-active',
				backgroundColor: 'yellow',
				borderColor: 'yellow',
				borderWidth: 0,
				opacity: 0.5,
				order: 2,

				data: data['qc-active'].slice(values[0], values[1])
			};
			setsOfData.push(obj);
		}

		if ('sensors' in data) {
			if ('landsat-8-l1tp' in data['sensors']) {
				let date = data['sensors']['landsat-8-l1tp']['captured'].slice(0, 10);
				let index = data.dates.indexOf(date);

				if (index < values[1] && index > values[0]) {
					let obj = {
						type: 'scatter', // Second dataset (point marker)
						label: 'landsat-8-l1tp',
						data: [{ x: date, y: 50 }], // Adjust this to the desired x and y values
						backgroundColor: 'white',
						borderColor: 'black',
						pointRadius: 5, // Adjust the size of the marker
						pointHoverRadius: 7,
						order: 1,
						pointStyle: 'rectRot'
					};

					setsOfData.push(obj);
				}
			}

			if ('sentinel-2-l1c' in data['sensors']) {
				let date = data['sensors']['sentinel-2-l1c']['captured'].slice(0, 10);
				let index = data.dates.indexOf(date);

				if (index < values[1] && index > values[0]) {
					let obj = {
						type: 'scatter', // Second dataset (point marker)
						label: 'sentinel-2-l1c',
						data: [{ x: date, y: 50 }], // Adjust this to the desired x and y values
						backgroundColor: 'white',
						borderColor: 'black',
						pointRadius: 5, // Adjust the size of the marker
						pointHoverRadius: 7,
						order: 1,
						pointStyle: 'crossRot'
					};
					setsOfData.push(obj);
				}
			}
		}

		setDataTop({
			labels: data.dates.slice(values[0], values[1]),
			datasets: setsOfData
		});
	};

	useEffect(() => {
		setData(plotData);
	}, [plotData]);

	// useEffect(() => {
	// 	if (data) {
	// 		let setsOfData = [];

	// 		if ('invalid' in data) {
	// 			let obj = {
	// 				type: 'bar',
	// 				label: 'invalid',
	// 				backgroundColor: 'darkgray',
	// 				borderColor: 'darkgray',
	// 				borderWidth: 0,
	// 				data: data['invalid']
	// 			};
	// 			setsOfData.push(obj);
	// 		}

	// 		if ('qc-invalid' in data) {
	// 			let obj = {
	// 				type: 'bar',
	// 				label: 'qc-invalid',
	// 				backgroundColor: 'darkgray',
	// 				borderColor: 'darkgray',
	// 				borderWidth: 0,
	// 				data: data['qc-invalid']
	// 			};
	// 			setsOfData.push(obj);
	// 		}

	// 		if ('inactive' in data) {
	// 			let obj = {
	// 				type: 'bar',
	// 				label: 'inactive',
	// 				backgroundColor: 'blue',
	// 				borderColor: 'blue',
	// 				borderWidth: 0,
	// 				data: data['inactive']
	// 			};
	// 			setsOfData.push(obj);
	// 		}

	// 		if ('qc-inactive' in data) {
	// 			let obj = {
	// 				type: 'bar',
	// 				label: 'qc-inactive',
	// 				backgroundColor: 'blue',
	// 				borderColor: 'blue',
	// 				borderWidth: 0,
	// 				data: data['qc-inactive']
	// 			};
	// 			setsOfData.push(obj);
	// 		}

	// 		if ('active' in data) {
	// 			let obj = {
	// 				type: 'bar',
	// 				label: 'active',
	// 				backgroundColor: 'orange',
	// 				borderColor: 'orange',
	// 				borderWidth: 0,

	// 				data: data['active']
	// 			};
	// 			setsOfData.push(obj);
	// 		}

	// 		if ('qc-active' in data) {
	// 			let obj = {
	// 				type: 'bar',
	// 				label: 'qc-active',
	// 				backgroundColor: 'orange',
	// 				borderColor: 'orange',
	// 				borderWidth: 0,

	// 				data: data['qc-active']
	// 			};
	// 			setsOfData.push(obj);
	// 		}

	// 		setDataBottom({
	// 			labels: data.dates,
	// 			datasets: setsOfData
	// 		});
	// 	}
	// }, [data]);

	useEffect(() => {
		if (data) {
				
			const setsOfData = Object.entries(exportConfig.barplotLabelsConfig).reduce((acc, [key, config]) => {
				if (key in data) {
					acc.push({
						type: 'bar',
						label: key,
						backgroundColor: config.backgroundColor,
						borderColor: config.borderColor,
						borderWidth: 0,
						data: data[key]
					});
				}
				return acc;
			}, []);
	
			setDataBottom({
				labels: data.dates,
				datasets: setsOfData
			});
		}
	}, [data]);
	

	useEffect(() => {
		if (data) {
			let captureDateDate = new Date(captureDate.slice(0, 10));
			let priorDateDate = new Date(
				new Date(captureDate.slice(0, 10)).setDate(
					captureDateDate.getDate() - 30
				)
			);

			//var priorDateDay, priorDateMonth;
			const priorDateMonth = String(priorDateDate.getMonth() + 1).padStart(2, '0');
			const priorDateDay = String(priorDateDate.getDate()).padStart(2, '0');

			// if (priorDateDate.getMonth() + 1 < 11) {
			// 	priorDateMonth = `0${priorDateDate.getMonth() + 1}`;
			// } else {
			// 	priorDateMonth = priorDateDate.getMonth() + 1;
			// }

			// if (priorDateDate.getDate() + 1 < 11) {
			// 	priorDateDay = `0${priorDateDate.getDate()}`;
			// } else {
			// 	priorDateDay = priorDateDate.getDate();
			// }

			// fix this
			let priorDate = `${priorDateDate.getFullYear()}-${priorDateMonth}-${priorDateDay}`;

			let indexEnd = data.dates.indexOf(captureDate.slice(0, 10));
			let indexStart = data.dates.indexOf(priorDate);
			
			let totalDays = data.dates.length;

			if (indexEnd === -1) {
				indexEnd = 30;
			}

			if (indexStart === -1) {
				indexStart = 0;
			}

			setStartIndex(indexStart);
			setEndIndex(indexEnd);
			setTotalDays(totalDays);

			let setsOfData = [];

			// if ('invalid' in data) {
			// 	let obj = {
			// 		type: 'bar',
			// 		label: 'invalid',
			// 		backgroundColor: 'darkgray',
			// 		borderColor: 'darkgray',
			// 		borderWidth: 0,
			// 		order: 2,
			// 		data: data['invalid'].slice(startIndex, endIndex)
			// 	};
			// 	setsOfData.push(obj);
			// }

			// if ('qc-invalid' in data) {
			// 	let obj = {
			// 		type: 'bar',
			// 		label: 'qc-invalid',
			// 		backgroundColor: 'gray',
			// 		borderColor: 'gray',
			// 		borderWidth: 0,
			// 		order: 2,
			// 		data: data['qc-invalid'].slice(startIndex, endIndex)
			// 	};
			// 	setsOfData.push(obj);
			// }

			// if ('inactive' in data) {
			// 	let obj = {
			// 		type: 'bar',
			// 		label: 'inactive',
			// 		backgroundColor: 'royalblue',
			// 		borderColor: 'royalblue',
			// 		borderWidth: 0,
			// 		order: 2,
			// 		data: data['inactive'].slice(startIndex, endIndex)
			// 	};
			// 	setsOfData.push(obj);
			// }

			// if ('qc-inactive' in data) {
			// 	let obj = {
			// 		type: 'bar',
			// 		label: 'qc-inactive',
			// 		backgroundColor: 'blue',
			// 		borderColor: 'blue',
			// 		borderWidth: 0,
			// 		order: 2,
			// 		data: data['qc-inactive'].slice(startIndex, endIndex)
			// 	};
			// 	setsOfData.push(obj);
			// }

			// if ('active' in data) {
			// 	let obj = {
			// 		type: 'bar',
			// 		label: 'active',
			// 		backgroundColor: 'goldenrod',
			// 		borderColor: 'goldenrod',
			// 		borderWidth: 0,
			// 		order: 2,

			// 		data: data['active'].slice(startIndex, endIndex)
			// 	};
			// 	setsOfData.push(obj);
			// }

			// if ('qc-active' in data) {
			// 	let obj = {
			// 		type: 'bar',
			// 		label: 'qc-active',
			// 		backgroundColor: 'yellow',
			// 		borderColor: 'yellow',
			// 		borderWidth: 0,
			// 		order: 2,

			// 		data: data['qc-active'].slice(startIndex, endIndex)
			// 	};
			// 	setsOfData.push(obj);
			// }

			for (const [key, config] of Object.entries(exportConfig.barplotLabelsConfig)) {
				if (key in data) {
					setsOfData.push({
						type: 'bar',
						label: key,
						backgroundColor: config.backgroundColor,
						borderColor: config.borderColor,
						borderWidth: 0,
						order: 2,
						data: data[key].slice(startIndex, endIndex)
					});
				}
			}

			if ('sensors' in data) {
				if ('landsat-8-l1tp' in data['sensors']) {
					let date = data['sensors']['landsat-8-l1tp']['captured'].slice(0, 10);
					let index = data.dates.indexOf(date);

					let obj = {
						type: 'scatter', // Second dataset (point marker)
						label: 'landsat-8-l1tp',
						data: [{ x: date, y: 50 }], // Adjust this to the desired x and y values
						backgroundColor: 'white',
						borderColor: 'black',
						pointRadius: 5, // Adjust the size of the marker
						pointHoverRadius: 7,
						order: 1,
						pointStyle: 'rectRot'
					};

					setsOfData.push(obj);
				}

				if ('sentinel-2-l1c' in data['sensors']) {
					let date = data['sensors']['sentinel-2-l1c']['captured'].slice(0, 10);
					let index = data.dates.indexOf(date);

					let obj = {
						type: 'scatter', // Second dataset (point marker)
						label: 'sentinel-2-l1c',
						data: [{ x: date, y: 70 }], // Adjust this to the desired x and y values
						backgroundColor: 'white',
						borderColor: 'black',
						pointRadius: 5, // Adjust the size of the marker
						pointHoverRadius: 7,
						order: 1,
						pointStyle: 'crossRot'
					};
					setsOfData.push(obj);
				}
			}

			setDataTop({
				labels: data.dates.slice(indexStart, indexEnd),
				datasets: setsOfData
			});
		}
	}, [data]);

	const optionsTop = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: xAxisFormat,
			y: {
				beginAtZero: true,
				ticks: {
					display: false
				},
				grid: {
					display: false
				}
			}
		},
		plugins: {
			legend: {
				labels: {
					usePointStyle: true // Use the point style specified in the dataset
				},
				display: true
			}
			// tooltip: {
			// 	callbacks: {
			// 		title: function (tooltipItems) {

			// 			if (data.hasOwnProperty('sensors')) {
			// 				if ((tooltipItems[0].dataset.type = 'scatter')) {
			// 					try {
			// 						let data2 = data['sensors'][tooltipItems[0].dataset.label];

			// 						return `${tooltipItems[0].label} - ${tooltipItems[0].dataset.label}:

			// 						Captured: ${data2['captured']}
			// 						Initial state: ${data2['initial_state']}
			// 						State: ${data2['state']}`;
			// 					} catch (e) {
			// 						console.log(e);
			// 					}
			// 				}

			// 				return `${tooltipItems[0].label} - ${tooltipItems[0].dataset.label}`;
			// 			}
			// 		},
			// 		label: function (tooltipItem) {
			// 			return '';
			// 		}
			// 	}
			// }
		},
		barPercentage: 1.0,
		categoryPercentage: 1.0
	};

	const optionsBottom = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				ticks: {
					font: {
					  size: 10,
					},
					
				  },
				type: 'time',
				time: {
					unit: 'month',
					tooltipFormat: 'yyyy MMM dd',
					displayFormats: {
						month: 'MMM yy'
					}
				},
				stacked: true,
				grid: {
					display: false
				},

				barPercentage: 1.0,
				categoryPercentage: 1.0,
				offset: false
			},
			y: {
				beginAtZero: true,
				ticks: {
					display: false
				},
				grid: {
					display: false
				}
			}
		},
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				callbacks: {
					title: function (tooltipItems) {
						return `${tooltipItems[0].label} - ${tooltipItems[0].dataset.label}`;
					},
					label: function (tooltipItem) {
						return '';
					}
				}
			}
		}
	};

	
	return (
		<>
			{plotDataLoading || loading ? (
				<fieldset
					className="form-group p-3 border rounded-3"
					style={{ height: '100%' }}
				>
					<legend className="float-none w-auto px-3 my-0 fs-6">
						Historic Data{' '}
						<button style={{ backgroundColor: '#f8f9fa', border: 'none', marginLeft: '3px', marginTop: '2px' }}>
							<ClipLoader size={15}  color='rgb(98, 152, 64)'/>
							{/* <i
								id="sentinel-hub-icon"
								data-tooltip-id="historic-plot-data"
								onClick={(e) => fetchBarplotData()}
								className="fa-solid fa-arrows-rotate ms-1 fa-sm"
								style={{ color: 'grey' }}
							></i> */}
						</button>
					</legend>

					<div style={{ position: 'relative', width: '100%', height: '100%' }}>
						<Skeleton
							style={{
								position: 'absolute',
								width: '100%',
								height: '100%'
							}}
						/>
						{/* <Plot
								data={[]}
								//layout={plotData.layout}
								useResizeHandler
								layout={layout}
								style={{ width: '100%', height: '280px' }} */}
					</div>
				</fieldset>
			) : plotDataError ? (
				<fieldset
					className="form-group p-3 border rounded-3"
					style={{ height: '100%' }}
				>
					<legend className="float-none w-auto px-3 my-0 fs-6 text-warning">
						Historic Data{' '}
						<button style={{ backgroundColor: '#f8f9fa', border: 'none' }}>
							<i
								id="sentinel-hub-icon"
								data-tooltip-id="historic-plot-data"
								onClick={(e) => fetchBarplotData()}
								className="fa-solid fa-arrows-rotate ms-1 fa-sm"
								style={{ color: '#ffc107' }}
							></i>
						</button>
					</legend>

					<div style={{ position: 'relative', width: '100%', height: '100%' }}>
						<Skeleton
							style={{
								position: 'absolute',
								width: '100%',
								height: '100%'
							}}
						/>
					</div>

					<Tooltip id="historic-plot-data" place="right" effect="solid">
				Historic data not fetched successfully; click to re-fetch
			</Tooltip>
				</fieldset>
			) : plotData ? (
				<fieldset
					className="form-group p-3 border rounded-3"
					style={{ height: '100%' }}
				>
					<legend className="float-none w-auto px-3 my-0 fs-6">
						Historic Data{' '}
						<button style={{ backgroundColor: '#f8f9fa', border: 'none' }}>
							<i
								id="sentinel-hub-icon"
								data-tooltip-id="historic-plot-data"
								onClick={(e) => fetchBarplotData()}
								className="fa-solid fa-arrows-rotate ms-1 fa-sm"
								style={{ color: 'rgb(98, 152, 64)' }}
							></i>
						</button>
					</legend>
					{/* <Tooltip id="historic-plot-data" place="right" effect="solid">
						reload Historic Data
					</Tooltip> */}

					{/* <Plot
						data={plotData.data}
						//layout={plotData.layout}
						useResizeHandler
						layout={plotData.layout}
						style={{ width: '100%', height: '280px' }}
					/> */}

					<div
						style={{
							position: 'relative',
							height: '90%',

							width: '100%'
							//border: 'solid 1px orange'
						}}
					>
						<div
							style={{
								position: 'absolute',
								//border: 'solid 1px yellow',
								width: '100%'
							}}
						>
							<div
								style={{
									//marginBottom: '20px',
									height: '200px',
								//	border: 'solid 1px red'
								}}
							>
								{dataTop && <Bar data={dataTop} options={optionsTop} />}
							</div>
							<div
								style={{
									height: '100px',
									//marginBottom: '20px',
									//border: 'solid 1px blue'
								}}
							>
								{dataBottom && (
									<Bar data={dataBottom} options={optionsBottom} />
								)}
							</div>
						</div>

						<div
							style={{
								position: 'absolute',
								height: '85px',
								overflow: 'hidden',
								width: '100%',
								//border: 'solid red 2px',
								top: '200px'
							}}
						>
							{dataTop && dataBottom && (
								<ReactSlider
									className="horizontal-slider"
									thumbClassName="thumb"
									trackClassName="track"
									max={totalDays}
									defaultValue={[startIndex, endIndex]}
									ariaLabel={['Lower thumb', 'Upper thumb']}
									ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
									renderThumb={(props, state) => (
										<div {...props} className="graphThumb"></div>
									)}
									pearling
									minDistance={10}
									onChange={handleSliderChange}
								/>
							)}
						</div>
					</div>
				</fieldset>
			) : (
				<fieldset
					className="form-group p-3 border rounded-3"
					style={{ height: '100%' }}
				>
					<legend className="float-none w-auto px-3 my-0 fs-6 text-warning">
						Historic Data{' '}
						<button style={{ backgroundColor: '#f8f9fa', border: 'none' }}>
							<i
								id="sentinel-hub-icon"
								data-tooltip-id="historic-plot-data"
								onClick={(e) => fetchBarplotData()}
								className="fa-solid fa-arrows-rotate ms-1 fa-sm"
								style={{ color: '#ffc107' }}
							></i>
						</button>
					</legend>

					<div style={{ position: 'relative', width: '100%', height: '100%' }}>
						<Skeleton
							style={{
								position: 'absolute',
								width: '100%',
								height: '100%'
							}}
						/>
					</div>

					<Tooltip id="historic-plot-data" place="right" effect="solid">
				Historic data not fetched successfully; click to re-fetch
			</Tooltip>
				</fieldset>
			)}

			
		</>
	);
};

export default Barplot;
