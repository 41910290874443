import React, { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import divideReturnQCList from '../../../helpers/divideReturnQCList';
import StockpilesTableBasic from '../../StockpilesTableBasic';
import SmeltersTableBasic from '../../SmeltersTableBasic';
import config from '../../../config';
import { pipelineContext } from '../../../contexts/pipelineContext';
import useGainsQCApi from '../../../services/useGainsQCApi';
import dummyData from '../../../helpers/postQCDummyData';
import { smeltersPostQCFields, smeltersPrePostQCFields } from '../tableFields';
import CapturesTable from '../../CapturesTable';
import { ClimbingBoxLoader, ClipLoader } from 'react-spinners';
import { SkeletonTable } from '../../SkeletonTable';
import dataToUpload from '../../../helpers/dataToUpload';
import Pako from 'pako';
import exportConfig from '../../../config';
import { Buffer } from 'buffer';

const QCUpload = ({ props, formValue }) => {
	const [manCapsUpload, setManCapsUpload] = useState([]);
	const [capsToSendBack, setCapsToSendBack] = useState([]);
	const [postQCPerformed, setPostQCPerformed] = useState(false);
	const [postQCData, setPostQCData] = useState();

	const [uploaded, setUploaded] = useState(false);
	const [uploadedToDev, setUploadedToDev] = useState(false);
	const [uploadedToProd, setUploadedToProd] = useState(false);
	const [loading, setLoading] = useState(false);

	const [indexCalcLoading, setIndexCalcLoading] = useState(false);
	const [uploadLoading, setUploadLoading] = useState(false);

	const [uploadToDatabase, setUploadToDatabase] = useState('dev');
	const [tablesData, setTablesData] = useState(null);
	const [toggleFill, setToggleFill] = useState(true);
	const [uniqueSites, setUniqueSites] = useState(0);
	const [socketUrl, setSocketUrl] = useState(null);
	const [socket, setSocket] = useState(null);
	const [receivedWSMsjs, setReceivedWSMsjs] = useState([]);
	const [indexCalcDone, setIndexCalcDone] = useState(false);
	const [dataUrl, setDataUrl] = useState(null);

	const [postQCWSError, setPostQCWSError] = useState([]);
	const [uploadError, setUploadError] = useState([]);

	const [lookback, setLookback] = useState(1);
	const [overwriteOnPostQC, setOverwriteOnPostQC] = useState(false);

	const { error: errorSendingCapsBack, makeRequest: sendCapsBack } =
		useGainsQCApi();
	const runPostQC = useGainsQCApi();
	const uploadToDB = useGainsQCApi();
	const uploadToDev = useGainsQCApi();
	const uploadToProd = useGainsQCApi();
	const runIndexCalc = useGainsQCApi();
	const fetchPostQCData = useGainsQCApi();

	const pipelineContextQCUpload = useContext(pipelineContext);

	let navigate = useNavigate();

	useEffect(() => {
		if (postQCData) {
			console.log(postQCData);
			const uniqueSiteIds = [
				...new Set(postQCData.map((item) => item.site_id))
			];
			setUniqueSites(uniqueSiteIds.length);
			console.log(uniqueSiteIds);

			const capturesGroupedById = [];

			for (let id in uniqueSiteIds) {
				let captureForThisSiteId = postQCData.filter(
					(obj) => obj.site_id === uniqueSiteIds[id]
				);
				console.log(captureForThisSiteId);

				if (toggleFill) {
					captureForThisSiteId = captureForThisSiteId.filter(
						(cap) => cap.filled !== 1
					);
				}

				capturesGroupedById.push(captureForThisSiteId);
			}

			setTablesData(capturesGroupedById);
		}
	}, [postQCData, toggleFill]);

	useEffect(
		() => {
			/**
			 * determine the caps to upload
			 * and caps to remove
			 */

			setManCapsUpload(
				pipelineContextQCUpload.comm === 'stockpiles'
					? pipelineContextQCUpload.capsInPipeline.filter(
							(cap) => cap['qc_state'] === 4
					  )
					: pipelineContextQCUpload.capsInPipeline.filter((cap) =>
							cap.hasOwnProperty('newQC')
					  )
			);

			setCapsToSendBack(
				pipelineContextQCUpload.comm === 'stockpiles'
					? pipelineContextQCUpload.capsInPipeline.filter(
							(cap) => cap['qc_state'] !== 4
					  )
					: pipelineContextQCUpload.capsInPipeline.filter(
							(cap) => cap.state === null
					  )
			);

			const uniqueSiteIds = [
				...new Set(
					pipelineContextQCUpload.capsInPipeline
						.filter((cap) => cap.state !== null)
						.map((item) => item.site_id)
				)
			];
			setUniqueSites(uniqueSiteIds.length);

			// needs to depend on progressBar to retrigger this calc when moving to upload
		},
		[
			//pipelineContextQCUpload.capsInPipeline,
			//pipelineContextQCUpload.progressBar
		]
	);

	let comms;
	if (pipelineContextQCUpload.comm === 'stockpiles') {
		comms = 7;
	} else {
		comms = '';
	}

	const runPostQCIndexCalc = async () => {
		/**
		 * perform index calculation
		 */

		setIndexCalcLoading(true);

		let siteIds1 = manCapsUpload.map((x) => x.site_id);
		const siteIds = [...new Set(siteIds1)];
		//const siteIds = '1001';

		// let user = `${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`;
		// let apiString = `post_qc?commodities=${comms}&siteIds=${siteIds}&overwrite=${overwriteOnPostQC}&username=${user}&lookback=-1`;

		// await runPostQC.makeRequest(
		// 	`${config.baseUrl}/${pipelineContextQCUpload.comm}/${apiString}`,
		// 	'POST',
		// 	null,
		// 	'post qc'
		// );

		var indexCalcBody;
		if (pipelineContextQCUpload.qcType === 'daily') {
			indexCalcBody = {
				prod_states: formValue.prod_states,
				commodities: formValue.commodities,
				lookback: lookback,
				overwrite: overwriteOnPostQC
			};
		} else {
			indexCalcBody = {
				site_ids: siteIds,
				lookback: lookback,
				overwrite: overwriteOnPostQC
			};
		}

		console.log(indexCalcBody);

		await runIndexCalc.makeRequest(
			//`${config.baseUrl}/${pipelineContextQCUpload.comm}/init-post-qc`,
			`${config.baseUrl}/smelters/init-post-qc`,
			'POST',
			{ site_ids: siteIds },
			'post qc'
		);
	};

	useEffect(() => {
		/**
		 * upon successful index calc, connect to websocket
		 */

		console.log(runIndexCalc.data);
		console.log(runIndexCalc.error);

		if (runIndexCalc.data && !runIndexCalc.error) {
			setSocketUrl(runIndexCalc.data.response_url);
			setIndexCalcLoading(true);
		} else {
			// TO DO: handle index calc error
			console.log(`Index Calc Error: ${runIndexCalc.error}`);
			setIndexCalcLoading(false);
		}
	}, [runIndexCalc.data, runIndexCalc.error]);

	useEffect(() => {
		/**
		 * upon successful index calc, open websock
		 */

		if (!socketUrl) return; // Don't open WebSocket if socketUrl is not provided

		const params = new URLSearchParams({
			execution_id: runIndexCalc.data.execution_id
		});

		// Step 1: Create a new WebSocket connection when socketUrl is available
		const ws = new WebSocket(`${socketUrl}?${params.toString()}`);
		setSocket(ws);

		// Step 2: Define event listeners for the WebSocket
		ws.onopen = () => {
			console.log('WebSocket connection opened');
		};

		ws.onmessage = (event) => {
			console.log(event);
			let data = JSON.parse(event.data);
			console.log(data);

			setReceivedWSMsjs((prevMessages) => [
				...prevMessages,
				{
					msg: data.message,
					time: event.timeStamp,
					url: data.hasOwnProperty('url') ? data.url : null
				}
			]);

			if (
				data.message === 'Process status is SUCCEEDED' &&
				data.hasOwnProperty('url')
			) {
				// Perform the additional fetch call
				const secondaryFetchUrl = data.url; // Replace with actual URL

				if (secondaryFetchUrl !== null) {
					fetch(secondaryFetchUrl)
						.then((response) => {
							if (!response.ok) {
								throw new Error('Failed to fetch secondary data');
							}
							return response.json();
						})
						.then((secondaryData) => {
							console.log('Secondary Data:', secondaryData);
							console.log(secondaryData);
							setPostQCData(secondaryData);
							// Handle secondary data as needed

							setIndexCalcDone(true);
							setIndexCalcLoading(false);
						})
						.catch((error) => {
							console.error('Error fetching secondary data:', error);
							setIndexCalcLoading(false);
						});
				} else {
					setIndexCalcLoading(false);
					setPostQCPerformed(true);
					console.log('url is null');
				}
			} else if (data.message === 'Process status is FAILED') {
				setIndexCalcLoading(false);
				setPostQCPerformed(false);
				console.log(data.message);
			}
		};

		ws.onerror = (error) => {
			console.error('WebSocket error:', error);
		};

		ws.onclose = () => {
			console.log('WebSocket connection closed');
		};

		return () => {
			console.log('Cleaning up WebSocket connection');
			ws.close();
		};
	}, [socketUrl]);

	// useEffect(() => {
	// 	/**
	// 	 * upon successful post QC
	// 	 * automatically upload data to dev
	// 	 */

	// 	console.log(runPostQC);
	// 	if (runPostQC.data && !runPostQC.error) {
	// 		setPostQCData(runPostQC.data.data);
	// 		setPostQCPerformed(true);

	// 		let apiString = `upload?data=${runPostQC.data.data}&commodity=${comms}&database=dev`;

	// 		uploadToDev.makeRequest(
	// 			`${config.baseUrl}/${pipelineContextQCUpload.comm}/${apiString}`,
	// 			'POST',
	// 			null,
	// 			'upload to dev'
	// 		);
	// 	} else {
	// 		console.log(runPostQC.error);
	// 	}
	// }, [runPostQC.data, runPostQC.error]);

	// useEffect(() => {
	// 	/**
	// 	 * upon successful upload to dev
	// 	 * re-enable Upload to Production button
	// 	 */

	// 	console.log(uploadToDev);

	// 	if (!uploadToDev.error && uploadToDev.data) {
	// 		if (uploadToDev.data.message === 'Success') {
	// 			setUploadedToDev(true);
	// 			setLoading(false);
	// 		}
	// 	}
	// }, [uploadToDev.data, uploadToDev.error]);

	// const uploadToProdDB = () => {
	// 	setLoading(true);

	// 	let comms;
	// 	if (pipelineContextQCUpload.comm === 'stockpiles') {
	// 		comms = 7;
	// 	} else {
	// 		comms = '';
	// 	}

	// 	let apiString = `upload?data=${postQCData}&commodity=${comms}&database=prod`;
	// 	console.log(apiString);

	// 	uploadToProd.makeRequest(
	// 		`${config.baseUrl}/${pipelineContextQCUpload.comm}/${apiString}`,
	// 		'POST',
	// 		null,
	// 		'upload to prod'
	// 	);
	// };

	const runUploadToDB = async () => {
		setUploadLoading(true);

		let bbody = {
			data: postQCData,
			lookback: lookback
		};

		let uploadBody;

		try {
			// Convert JSON object to a string
			const jsonString = JSON.stringify(bbody);

			// Compress the JSON string using pako
			const compressedData = Pako.gzip(jsonString);
			console.log(compressedData);

			//uploadBody = btoa(unescape(encodeURIComponent(compressedData)));
			uploadBody = Buffer.from(compressedData).toString('base64');

			console.log(uploadBody);

			uploadToDB.makeRequest(
				`${exportConfig.baseUrl}/${pipelineContextQCUpload.comm}/upload`,
				'POST',
				uploadBody,
				'upload to database'
			);
		} catch (error) {
			console.error('Error compressing JSON:', error);
		}
	};

	useEffect(() => {
		console.log(uploadToDB.error);
		console.log(uploadToDB.data);

		if (!uploadToDB.error && uploadToDB.data) {
			pipelineContextQCUpload.setUploadPerformed(true);
			setUploadLoading(false);
			setUploaded(true);
		}
		if (uploadToDB.error) {
			setUploadLoading(false);
		}
	}, [uploadToDB.data, uploadToDB.error]);

	// useEffect(() => {
	// 	/**
	// 	 * upon successful upload to prod
	// 	 * QC in progress now finished
	// 	 */

	// 	console.log(uploadToProd);

	// 	if (!uploadToProd.error && uploadToProd.data) {
	// 		if (uploadToProd.data.message === 'Success') {
	// 			setLoading(false);
	// 			setUploaded(true);
	// 			setUploadedToProd(true);
	// 			pipelineContextQCUpload.setQCInProgress(false);
	// 		}
	// 	}
	// }, [uploadToProd.data, uploadToProd.error]);

	const renderDevUploadTT = () => {
		if (!indexCalcLoading && !postQCPerformed && overwriteOnPostQC) {
			return (
				<Tooltip id="performPostQCTT" place="bottom" effect="solid">
					Perform Post-QC, upload to development and <strong>overwrite</strong>{' '}
					database
				</Tooltip>
			);
		}

		if (!indexCalcLoading && !postQCPerformed && !overwriteOnPostQC) {
			return (
				<Tooltip id="performPostQCTT" place="bottom" effect="solid">
					Perform Post-QC, upload to development but{' '}
					<strong>do not overwrite</strong> database
				</Tooltip>
			);
		}

		if (indexCalcLoading) {
			return (
				<Tooltip id="performPostQCTT" place="bottom" effect="solid">
					Performing Post-QC and uploading to development...
				</Tooltip>
			);
		}

		if (!indexCalcLoading && postQCPerformed) {
			return (
				<Tooltip id="performPostQCTT" place="bottom" effect="solid">
					Post-QC already performed and captures uploaded to development
				</Tooltip>
			);
		}

		return null;
	};

	const renderProdUploadTT = () => {
		if (!postQCPerformed) {
			return (
				<Tooltip id="uploadCapturesTT" place="bottom" effect="solid">
					You cannot upload captures before Post-QC has been performed and they
					have been uploaded to development
				</Tooltip>
			);
		}

		if (!loading && postQCPerformed && !uploaded) {
			return (
				<Tooltip id="uploadCapturesTT" place="bottom" effect="solid">
					Upload captures to the <strong>production</strong> database
				</Tooltip>
			);
		}

		if (postQCPerformed && uploaded) {
			return (
				<Tooltip id="uploadCapturesTT" place="bottom" effect="solid">
					Captures already uploaded to production
				</Tooltip>
			);
		}
	};

	const renderPostQCButton = () => {
		if (!indexCalcLoading && !indexCalcDone) {
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButton"
						data-tooltip-id="performPostQCTT"
						onClick={(e) => runPostQCIndexCalc()}
					>
						Perform Post QC
					</button>

					<Tooltip id="performPostQCTTNotDone" place="bottom" effect="solid">
						Click to start the Post QC process
					</Tooltip>
				</>
			);
		}

		if (indexCalcLoading && !indexCalcDone) {
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButtonDisabled"
						data-tooltip-id="performPostQCTTPerforming"
					>
						<span>Performing Post QC</span>
						<div className="animated-ellipsis ps-1">
							<span>.</span>
							<span>.</span>
						</div>
					</button>
					<Tooltip id="performPostQCTTPerforming" place="bottom" effect="solid">
						Performing index calculation ..
					</Tooltip>
				</>
			);
		}

		if ((!indexCalcLoading && indexCalcDone) || postQCPerformed) {
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButtonDisabled"
						data-tooltip-id="performPostQCTTPerformed"
					>
						Performed Post QC <i className="ps-1 fa-solid fa-circle-check"></i>
					</button>
					<Tooltip id="performPostQCTTPerformed" place="bottom" effect="solid">
						Completed index calculation
					</Tooltip>
				</>
			);
		}

		return (
			<button
				style={{ width: '200px' }}
				className="btn interactButtonDisabled"
				data-tooltip-id="performPostQCTT"
				disabled
			>
				Post QC
			</button>
		);
	};

	const renderUploadButton = () => {
		//

		if (!indexCalcDone) {
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButton"
						data-tooltip-id="performUploadTTICNotDone"
						disabled
						//onClick={(e) => runPostQCIndexCalc()}
					>
						Upload to database
					</button>
					<Tooltip id="performUploadTTICNotDone" place="bottom" effect="solid">
						Complete previous steps before performing upload
					</Tooltip>
				</>
			);
		}

		if (!indexCalcLoading && indexCalcDone && !postQCData && postQCPerformed) {
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButton"
						data-tooltip-id="uploadButtonNoData"
						disabled
					>
						<span>No data to upload</span>
					</button>
					<Tooltip id="uploadButtonNoData" place="bottom" effect="solid">
						There is no data to upload to the database. You can finish QC.
					</Tooltip>
				</>
			);
		}

		if (
			indexCalcDone &&
			!indexCalcLoading &&
			!uploadLoading &&
			!postQCPerformed &&
			postQCData &&
			!uploaded
		) {
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButton"
						data-tooltip-id="performUploadUpload"
						onClick={(e) => runUploadToDB()}
					>
						<span>Upload to database</span>
						{/* <span className="AnimatedEllipsis ps-3"></span> */}
					</button>
					<Tooltip id="performUploadUpload" place="bottom" effect="solid">
						Upload the performed calculation to the database
					</Tooltip>
				</>
			);
		}

		if (uploadLoading) {
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButtonDisabled"
						data-tooltip-id="performUploadUploading"
						disabled
						//onClick={(e) => runPostQCIndexCalc()}
					>
						<span>Uploading</span>
						<div className="animated-ellipsis ps-1">
							<span>.</span>
							<span>.</span>
						</div>
					</button>
					<Tooltip id="performUploadUploading" place="bottom" effect="solid">
						Uploading the performed calculation to the database
					</Tooltip>
				</>
			);
		}

		if (uploaded) {
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButtonDisabled"
						data-tooltip-id="performUploadUploaded"
					>
						Uploaded <i className="ps-1 fa-solid fa-circle-check"></i>
					</button>
					<Tooltip id="performUploadUploaded" place="bottom" effect="solid">
						Successfully uploaded to the database
					</Tooltip>
				</>
			);
		}

		return null;
	};

	const renderFinishQCButton = () => {
		if (
			(!uploadLoading && uploaded) ||
			(!indexCalcLoading && indexCalcDone && !postQCData && postQCPerformed)
		) {
			return (
				<>
					<button
						className="btn interactButton"
						data-tooltip-id="QCfinishedTT"
						style={{ width: '200px' }}
						onClick={(e) => finishQC()}
					>
						Finish QC
						<i className="ps-2 fa-solid fa-circle-check"></i>
					</button>
					<Tooltip id="QCfinishedTT" place="bottom" effect="solid">
						You can now finish QC and review the report
					</Tooltip>
				</>
			);
		} else {
			return (
				<>
					<button
						className="btn interactButtonDisabled"
						data-tooltip-id="QCNotfinishedTT"
						style={{ width: '200px' }}
					>
						Finish QC
					</button>
					<Tooltip id="QCNotfinishedTT" place="bottom" effect="solid">
						Complete all steps of upload before completing QC
					</Tooltip>
				</>
			);
		}
	};

	const finishQC = () => {
		if (capsToSendBack.length > 0) {
			sendCapsBack(
				`${config.baseUrl}/${pipelineContextQCUpload.comm}/remove_from_queue`,
				'POST',
				capsToSendBack.map((cap) => cap.id),
				'sending non-QCd caps back'
			);
		}

		// reset everything else here
		//pipelineContextQCUpload.setCapsInPipeline([]);
		pipelineContextQCUpload.setProgressBar({ width: 100, title: 'QC Report' });
		pipelineContextQCUpload.setPostQCPerformed(true);
		pipelineContextQCUpload.setQCInProgress(false);
	};

	console.log(loading);
	console.log(capsToSendBack);
	console.log(indexCalcLoading);
	console.log(indexCalcDone);
	console.log(postQCData);
	console.log(postQCPerformed);
	console.log(socketUrl);
	console.log(receivedWSMsjs);
	console.log(manCapsUpload);
	console.log(lookback);
	console.log(overwriteOnPostQC);
	console.log(tablesData);
	console.log(uploadLoading);
	console.log(uploaded);
	

	return (
		<>
			<div style={{ padding: '10px' }}>
				<div className="row mb-2">
					<hr></hr>

					<div className="row">
						<div className="col">
							<h2>Upload Captures</h2>
							<h5>Use this page to upload QC'd captures to the database</h5>
						</div>
						{/* <div className="col d-flex justify-content-end">
							{runIndexCalc.error && (
								<p className="text-danger">
									<i className="fa-solid fa-triangle-exclamation pe-2"></i>{' '}
									There has been an error performing the Index Calculation.
									<br></br> Please try again, or Exit QC and try again later.
								</p>
							)}

							{runPostQC.error && (
								<p className="text-danger">
									<i className="fa-solid fa-triangle-exclamation pe-2"></i>{' '}
									There has been an error performing Post-QC. Upload cannot be
									performed on these captures. Please exit QC and try again
									later.
								</p>
							)}
							{uploadToDev.error && (
								<p className="text-danger">
									<i className="fa-solid fa-triangle-exclamation pe-2"></i>{' '}
									There has been an error uploading to the <strong>dev</strong>{' '}
									database. Upload cannot be performed on these captures. Please
									exit QC and try again later.
								</p>
							)}
							{uploadToProd.error && (
								<p className="text-danger">
									<i className="fa-solid fa-triangle-exclamation pe-2"></i>{' '}
									There has been an error uploading to the <strong>prod</strong>{' '}
									database. Upload cannot be performed on these captures. Please
									exit QC and try again later.
								</p>
							)}
						</div> */}
					</div>
				</div>

				{/* <div className="row">
					<div className="col  d-flex justify-content-center">
						{!postQCPerformed && (
							<td
								className="px-4 py-2 "
								style={{ whiteSpace: 'nowrap' }}
								rowSpan={2}
							>
								<div className="d-flex flex-column bg-light">
									<p>
										Perform post-QC and upload to development<br></br>
										Overwrite existing data in database?
									</p>

									<div>
										<input
											className="form-check-input form-commodity"
											key="overwrite1"
											type="radio"
											id="overwritePostQCFalse"
											name="overwritePostQC"
											value={false}
											onChange={(e) => setOverwriteOnPostQC(false)}
											checked={overwriteOnPostQC === false}
										></input>
										<label
											for="overwritePostQCFalse"
											className="px-2 form-label"
										>
											Do not overwrite
										</label>
									</div>
									<div>
										<input
											className="form-check-input form-commodity"
											key="overwrite2"
											type="radio"
											id="overwritePostQCTrue"
											name="overwritePostQC"
											value={true}
											onChange={(e) => setOverwriteOnPostQC(true)}
											checked={overwriteOnPostQC === true}
										></input>
										<label
											for="overwritePostQCTrue"
											className="px-2 form-label"
										>
											Overwrite
										</label>
									</div>
								</div>
							</td>
						)}
					</div>
				</div> */}

				<div className="row my-2">
					<table style={{ width: '70%', marginLeft: '15%' }}>
						<tbody>
							<tr>
								<td className="d-flex justify-content-center align-items-center p-0">
									{' '}
									<label className="label me-3" for="upload-change-lookback">
										Lookback
									</label>
									<input
										id="upload-change-lookback"
										className="form-control my-1 text-center p-0"
										type="number"
										style={{ width: '80px' }}
										onChange={(e) => setLookback(e.target.value)}
										min={-1}
										max={7}
										value={lookback}
										disabled={indexCalcDone}
									></input>
								</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td className="d-flex justify-content-center align-items-center pt-0">
									<div className="form-check form-switch ms-3 mt-2">
										<label
											className="form-check-label"
											for="flexSwitchCheckDefault2"
										>
											Overwrite existing data
										</label>
										<input
											className="form-check-input"
											type="checkbox"
											id="flexSwitchCheckDefault2"
											onChange={() =>
												setOverwriteOnPostQC((prevState) => !prevState)
											}
											disabled={indexCalcDone}
										></input>
									</div>
								</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td className="text-center">{renderPostQCButton()}</td>
								<td className="text-center">
									{indexCalcLoading ? (
										<ClipLoader size={18} />
									) : (
										<i class="fa-solid fa-circle-arrow-right"></i>
									)}
								</td>
								<td className="text-center">{renderUploadButton()}</td>
								{/* <td className="text-center">
									<i class="fa-solid fa-circle-arrow-right"></i>
								</td> */}
								{/* <td className="text-center">{renderProdUploadButton()}</td> */}
								<td className="text-center">
									{uploadLoading ? (
										<ClipLoader size={18} />
									) : (
										<i class="fa-solid fa-circle-arrow-right"></i>
									)}
								</td>
								<td className="text-center">{renderFinishQCButton()}</td>
							</tr>

							<tr>
								<td className="text-center fw-bold">
									{runIndexCalc.error && (
										<i
											data-tooltip-id="indexCalcErrorTT"
											className="fa-solid fa-triangle-exclamation pe-2 text-danger"
										></i>
									)}{' '}
									Step 1
								</td>
								<td className="text-center"></td>
								<td className="text-center fw-bold">
									{uploadToDev.error && (
										<i
											data-tooltip-id="uploadErrorTT"
											className="fa-solid fa-triangle-exclamation pe-2 text-danger"
										></i>
									)}{' '}
									Step 2
								</td>
								<td className="text-center"></td>
								<td className="text-center fw-bold">Step 3</td>
								{/* <td className="text-center"></td>
								<td className="text-center fw-bold">Step 4</td> */}
							</tr>
						</tbody>
					</table>
				</div>

				<Tooltip id="indexCalcErrorTT" place="bottom" effect="solid">
					<i className="fa-solid fa-triangle-exclamation pe-2"></i> There has
					been an error performing the Index Calculation.
					<br></br> Please try again, or Exit QC and try again later.
				</Tooltip>

				<Tooltip id="uploadErrorTT" place="bottom" effect="solid">
					There has been an error performing Post-QC. <br></br>Upload cannot be
					performed on these captures. Please exit QC and try again later.
				</Tooltip>

				{!pipelineContextQCUpload.straightToUpload ? (
					<>
						<div className="row mt-5 px-2">
							{/* <h5>Captures QC'd ({manCapsUpload.length})</h5> */}

							<div className="row mb-3">
								<div className="col d-flex align-items-center">
									{indexCalcDone && (
										<div className="form-check form-switch ms-3 mt-2">
											<input
												className="form-check-input"
												type="checkbox"
												id="flexSwitchCheckDefault1"
												onChange={(e) =>
													setToggleFill((prevState) => !prevState)
												}
											></input>
											<label
												className="form-check-label"
												for="flexSwitchCheckDefault1"
											>
												View captures where{' '}
												<span className="fw-bold">fill = 1</span>
											</label>
										</div>
									)}
								</div>
								<div className="col d-flex justify-content-end  align-items-center">
									Total captures: {manCapsUpload.length} / sites : {uniqueSites}
								</div>
							</div>

							{/* {pipelineContextQCUpload.comm === 'stockpiles' && (
								<div className="table-responsive" style={{ maxHeight: '40vh' }}>
									<StockpilesTableBasic
										props={props}
										captures={manCapsUpload}
										capturesToReset={[]}
										postQCPerformed={postQCPerformed}
										uploadedToDev={uploadedToDev}
										uploadedToProd={uploadedToProd}
									/>
								</div>
							)} */}

							{/* {pipelineContextQCUpload === 'smelters' && ( */}

							{!runIndexCalc.loading && !postQCData && !socketUrl ? (
								<SmeltersTableBasic
									props={props}
									captures={manCapsUpload}
									capturesToReset={[]}
								/>
							) : !runIndexCalc.loading &&
							  postQCData &&
							  tablesData &&
							  socketUrl ? (
								<>
									{tablesData.map((site2, idx) => (
										<div
											key={idx}
											className="table-responsive"
											style={{
												maxWidth: '100%',
												maxHeight: '500px',
												overflowY: 'scroll'
											}}
										>
											<h5>Site ID: {site2.length > 0 && site2[0].site_id}</h5>
											<table className="table">
												<thead className="table-light">
													<tr>
														{smeltersPostQCFields
															.map((item) => item.header)
															.map((key) => (
																<th>{key}</th>
															))}
													</tr>
												</thead>

												<tbody className="table-group-divider">
													{site2.map((site) => (
														<tr>
															<td>
																{site.hasOwnProperty('id') ? site.id : null}
															</td>
															<td>
																{site.hasOwnProperty('captured')
																	? site.captured
																	: null}
															</td>
															<td>
																{site.hasOwnProperty('site_id')
																	? site.site_id
																	: null}
															</td>
															<td>
																{site.hasOwnProperty('sensor')
																	? site.sensor
																	: null}
															</td>
															<td>
																{site.hasOwnProperty('state')
																	? site.state
																	: null}
															</td>
															<td>
																{site.hasOwnProperty('change')
																	? site.change
																	: null}
															</td>
															<td>
																{site.hasOwnProperty('cloud_frac')
																	? site.cloud_frac.toFixed(4)
																	: null}
															</td>
															<td>
																{site.hasOwnProperty('raw_index_score')
																	? site.raw_index_score
																	: null}
															</td>
															<td>
																{site.hasOwnProperty(
																	'normalized_raw_index_score_6'
																)
																	? site.normalized_raw_index_score_6.toFixed(4)
																	: null}
															</td>
															<td>
																{site.hasOwnProperty(
																	'normalized_raw_index_score_7'
																)
																	? site.normalized_raw_index_score_7.toFixed(4)
																	: null}
															</td>
															<td>
																{site.hasOwnProperty(
																	'normalized_raw_index_score_8'
																)
																	? site.normalized_raw_index_score_8.toFixed(4)
																	: null}
															</td>
															<td>
																{site.hasOwnProperty(
																	'normalized_raw_index_score_9'
																)
																	? site.normalized_raw_index_score_9.toFixed(4)
																	: null}
															</td>
															<td>
																{site.hasOwnProperty('dispersion_index')
																	? site.dispersion_index
																	: null}
															</td>
															<td>
																{site.hasOwnProperty('working')
																	? site.working
																	: null}
															</td>
															<td>
																{site.hasOwnProperty('filled')
																	? site.filled
																	: null}
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									))}
								</>
							) : (
								<SkeletonTable
									numRows={10}
									cols={smeltersPostQCFields.map((item) => item.header)}
									height={300}
								/>
							)}

							{/* {!loading && !indexCalcDone && !socketUrl && !postQCPerformed && tablesData && (
								<div className="my-3">
									<CapturesTable
										fields={smeltersPrePostQCFields}
										captures={dummyData}
										qcCat={'smelters'}
										currentCaptureIndex={null}
										tableHeight={'445px'}
									/>
								</div>
							)} */}
						</div>

						{/* {capsToSendBack.length > 0 && (
						<Row className="mt-5 px-2">
							<h5>Captures not QC'd ({capsToSendBack.length})</h5>
							<p>
								These captures will be removed from your pipeline when you
								complete the QC process
							</p>

							<div className="table-responsive" style={{ maxHeight: '40vh' }}>
								{pipelineContextQCUpload.comm === 'stockpiles' && (
									<div
										className="table-responsive"
										style={{ maxHeight: '40vh' }}
									>
										<StockpilesTableBasic
											props={props}
											captures={capsToSendBack}
											capturesToReset={[]}
										/>
									</div>
								)}

								{pipelineContextQCUpload === 'smelters' && (
									<div
										className="table-responsive"
										style={{ maxHeight: '40vh' }}
									>
										<SmeltersTableBasic
											props={props}
											captures={capsToSendBack}
											capturesToReset={[]}
										/>
									</div>
								)}
							</div>
						</Row>
					)} */}
					</>
				) : (
					<>
						<div className="row mt-5 px-2">
							<h5>Captures QC'd ({manCapsUpload.length})</h5>

							{!uploaded && !postQCPerformed && (
								<p>
									These captures have been QC'd and are available to be
									uploaded. <br></br>Perform <strong>Post-QC</strong> first,
									then choose the database and perform <strong>upload</strong>.
								</p>
							)}

							{!uploaded && postQCPerformed && (
								<p>
									These captures have been QC'd, Post-QC has been performed and
									are ready to be uploaded. <br></br>Choose the database and
									perform <strong>upload</strong>.
								</p>
							)}

							{uploaded && postQCPerformed && (
								<p>
									These captures have been successfully uploaded to the{' '}
									<strong>{uploadToDatabase}</strong> database. <br></br>You can
									now finish QC and view the report for these captures.
								</p>
							)}

							{pipelineContextQCUpload.comm === 'stockpiles' && (
								<div className="table-responsive" style={{ maxHeight: '40vh' }}>
									<StockpilesTableBasic
										props={props}
										//captures={manCapsUpload}
										//captures={}
										capturesToReset={[]}
										postQCPerformed={postQCPerformed}
										uploadedToDev={uploadedToDev}
										uploadedToProd={uploadedToProd}
										allowJumpCapture={false}
									/>
								</div>
							)}

							{pipelineContextQCUpload.comm === 'smelters' && (
								<div className="table-responsive" style={{ maxHeight: '40vh' }}>
									<SmeltersTableBasic
										props={props}
										captures={manCapsUpload}
										capturesToReset={[]}
									/>
								</div>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default QCUpload;
